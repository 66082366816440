#{$ap}- {
    &tab {
        &__ {
            &item {
                & .nav-link {
                    font-size: var(--text-md);
                    color: var(--color-black);
                    border: none !important;
                    margin-right: var(--size-10);
                    padding: var(--size-3) 0;
                    cursor: pointer;
                    user-select: none;
                    -webkit-user-select: none;
                    position: relative;
                    transition: all 0.2s ease;

                    @include respond-below(sm){
                        margin:0 var(--size-3) 0 var(--size-3);
                        font-size:var(--text-sm);
                    }

                    &.active {
                        background: none !important;
                        color: var(--color-primary);
                        &:after {
                            content: "";
                            width: 100%;
                            height: 4px;
                            background-color: var(--color-primary);
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        }
                    }

                    &:hover {
                    }
                }
            }

            &filter{
                &-{
                    &group{
                        margin-bottom:var(--size-6);
                    }

                    &grouptext{
                      border-left:none;
                    }

                    &dropdown{
                        color:var(--color-white);
                        background: rgba(255,255,255,0.28);
                        transition:all 0.25s ease-in-out;-webkit-transition:all 0.25s ease-in-out;-moz-transition:all 0.25s ease-in-out;
                        
                        &:hover{
                            background: var(--color-blue-2);
                            transition:all 0.25s ease-in-out;-webkit-transition:all 0.25s ease-in-out;-moz-transition:all 0.25s ease-in-out;
                        }

                        &.show > .dropdown-toggle:after{
                            transform:rotate(180deg);-webkit-transform:rotate(180deg);-moz-transform:rotate(180deg);
                            transition:all 0.25s ease-in-out;-webkit-transition:all 0.25s ease-in-out;-moz-transition:all 0.25s ease-in-out;
                        }

                        &--{
                            &toggle{
                                color:var(--color-white);
                                padding: calc(var(--size-3) - 0.5px) var(--size-4) !important;
                            
                                &:hover{
                                    color:var(--color-white);
                                }

                                @include respond-below(sm){
                                    padding:calc(var(--size-3) + 1px) var(--size-2) !important;
                                }

                               
                            }
                            &icon{
                                font-size:var(--text-lg);

                                @include respond-above(md){
                                    font-size: var(--text-xl);
                                }
                            }
                            &menu{
                                padding:var(--size-4) var(--size-3);
                                width:350px;
                                height:400px;
                                right:0;
                                left:unset !important;
                                border:none !important;
                                box-shadow: 0px 3px 10px #00000033;
                                z-index: 50 !important;
                                overflow-y:auto;

                                @include respond-below(sm){
                                    width:300px;
                                }
                            }
                            &text{
                                padding-right:calc(var(--size-10) * 3);
                            }
                            &searchbar{
                                
                            }
                            &reset{
                                text-decoration: none;
                                color:var(--color-danger);
                                text-transform: uppercase;
                                font-size: var(--text-sm);
                                margin-top: var(--size-1);
                                letter-spacing: 1.2px;

                                &:hover, &:focus{
                                    text-decoration: none !important;
                                    color:var(--color-danger);
                                }
                            }
                        }
                    }
                    &search{
                        color:var(--color-grey-25);
                        
                        &--{
                            &paymentcard{
                                color:var(--color-grey-22);
                                &:hover{
                                    color:var(--color-grey-22);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}