#{$ap}-{
    &review{
        margin-bottom: var(--size-10);

        &:last-child {
            margin-bottom: 0;
        }
        &__{
        
            &rating{
                &s {
                    font-size: var(--text-3xl);
                    line-height: calc(var(--text-3xl) + 8px);
                    font-weight: var(--font-semibold);
                    color:var(--color-black-2);

                    &- {
                        &wrap {
                            margin-bottom: 30px;
                            // border-bottom: 1px solid var(--color-grey-14);
                        }
                        &platform {
                            width: 46px;
                        }
                        &text {
                            font-size: var(--text-xl);
                            line-height: calc(var(--text-3xl) + 8px);
                            font-weight: var(--font-medium);
                            color: var(--color-grey-12);

                            @include respond-above(md){
                                font-size: var(--text-3xl);
                            }
                        }
                    }
                }
                &-{
                    
                    &img{
                        width: 44px;
                        height: 44px;
                        overflow: hidden;

                    }
                    &text{
                        font-size: var(--text-3xl);
                        font-weight: var(--font-semibold);
                        color:var(--color-grey-12);
                    }
                }
            }
            &profile{
                &-{
                    &img {
                        width:50px;
                        height: 50px;
                        border-radius: 100%;
                        overflow: hidden;
                        background-color: var(--color-grey-12);
                        background-size: cover;
                        background-repeat: no-repeat;
                    }
                    &name{
                        font-size: var(--text-md);
                        line-height: calc(var(--text-xl) + 1px);
                        font-weight:var(--font-medium);
                        color: var(--color-black-2);
                    }
                    &rating {
                        &-- {
                            &number{
                                font-weight:var(--font-medium);
                                color: var(--color-black-2);
                                font-size: var(--text-md);
                            }
                            &icon {
                                color: var(--color-yellow);
                            }
                        }
                    }
                }
            }
        }
    }
}