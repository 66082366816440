@import "./header", "./footer";

#{$ap}- {
    &main {
        padding-top: calc(var(--size-12) + 15px);
        min-height: calc(100vh - 170px);

        @include respond-above(sm) {
            padding-top: calc(var(--size-12) + 15px);
            min-height: calc(100vh - 170px);
        }

        &__login {
            height: 100vh;
        }
    }
    &section {
        padding: 20px 0;

        @include respond-above(lg) {
            padding: 20px 0;
        }
        // @include respond-below(md){
        //     margin-bottom:calc(var(--size-10) * 2);
        // }
        // @include respond-below(sm){
        //     margin-bottom:calc(var(--size-12) + 10px);
        // }

        &__ {
            &pages {
                padding: var(--size-8) 0;

                @include respond-above(sm) {
                    padding: var(--size-10) 0 var(--size-10);
                }
            }

            &empty {
                @include respond-above(md) {
                    // margin-top: 70px;
                }
                &- {
                    &content {
                        width: 100%;
                        bottom: 0;
                        // width: calc(100% - 400px);
                        height: 100%;
                        padding: 0 10px;

                        @media (min-width: 768px) {
                            padding: 0 40px;
                        }

                        @media (min-width: 992px) {
                            padding: 0 80px;
                        }
                    }
                    &title {
                        width: calc(100% - 100px);
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: var(--font-bold);

                        @media (min-width: 768px) {
                            width: calc(100% - 200px);
                            font-size: 24px;
                            line-height: 28px;
                        }

                        @media (min-width: 992px) {
                            width: calc(100% - 400px);
                            font-size: 28px;
                            line-height: 38px;
                        }
                        @include respond-above(lg) {
                            width: calc(100% - 400px);
                            font-size: 40px;
                            line-height: 50px;
                        }
                    }
                    &img {
                        width: 100px;
                        bottom: 0;
                        right: 0;
                        @media (min-width: 768px) {
                            width: 200px;
                        }
                        @media (min-width: 992px) {
                            width: 400px;
                        }
                    }
                }
            }
        }

        &-- {
            &gray {
                background-color: var(--color-section-bg);
            }
            &white {
                background-color: var(--color-white);
            }
        }
    }

    //- Global Modal Popup
    &global {
        &__ {
            &modal {
                top: 10% !important;

                &- {
                    &header {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}
