#{$ap}-{
    &payment{
        &__{
            &wrap{
                border: 1px solid var(--color-grey-31);
                border-radius: var(--rounded);
            }
            &title{
                font-size: var(--text-lg);
                font-weight: var(--font-bold);
                color: var(--color-grey-32);
            }
            &card {
                border-radius: var(--rounded);
                background-color: #fbfbfb;
                padding: var(--size-4);

                @include respond-above(md) {
                    padding: var(--size-6);
                }

                &-- {
                    &border {
                        border: 1px solid var(--color-grey-33);
                    }
                    &nobackground {
                        background: none;
                    }
                }
            }
            &label {
                &- {
                    &summary {
                        font-size: var(--text-xs);
                        font-weight: var(--font-bold);
                        color: var(--color-grey-32);
                    }
                    &budjet {
                        font-size: var(--text-md);
                        font-weight: var(--font-light);
                        color: var(--color-grey-27);
                        &-- {
                            &green {
                                font-size: var(--text-md);
                                font-weight: var(--font-light);
                                color: var(--color-secondary);
                            }
                        }
                    }
                }
            }
            &amount {
                font-size: 30px;
                font-weight: 500;
            }
            &answer {
                &- {
                    &summary {
                        font-size: var(--text-md);
                        font-weight: var(--font-normal);
                        color: var(--color-grey-32);
                    }
                    &budjet {
                        font-size: var(--text-lg);
                        font-weight: var(--font-light);
                        color: var(--color-black);
                        &-- {
                            &bold {
                                font-size: var(--text-lg);
                                font-weight: var(--font-medium);
                                color: var(--color-grey-28);
                            }
                        }
                    }
                }
            }
            
            &final{
                font-size: var(--text-xl);
                font-weight: var(--font-medium);
                color: var(--color-secondary);
                border-bottom: 1px dotted var(--color-grey-5);
                &-{
                    &amount{
                        font-size: var(--text-xl);
                        font-weight: var(--font-medium);
                        color: var(--color-black);
                    }
                    &description{
                        font-size: var(--text-sm);
                        font-weight: var(--font-light);
                        color: var(--color-grey-25);
                    }
                }
            }
            &info{
                background-color: var(--color-grey-34);
            }
            &card {
                &- {
                    &label {
                        font-size: var(--text-xs);
                        font-weight: var(--font-normal);
                        color: var(--color-grey-32);
                    }
                }
            }
            &button{
                font-size: var(--text-md);
                font-weight: var(--font-normal);
                color: var(--color-white);
                background-color: var(--color-secondary);
                border-radius: var(--rounded);
                width: 160px;
                cursor: pointer;
            }
        }
    }
}