#{$ap}-{
    &myprojects{
        &__{
            &inbidding{
                &-{
                    &amount{
                        width: 45%;
                        background-color: var(--color-grey-21);
                    }
                    &bid{
                        font-size: var(--text-sm);
                        font-weight: var(--font-normal);
                        color: var(--color-grey-22);
                        i{
                            color: var(--color-black) !important;
                            font-size: var(--text-md);
                            font-weight: var(--font-bold);
                        }
                    }
                    &text{
                        font-size: var(--text-xxs);
                        font-weight: var(--font-light);
                        color: var(--color-grey-23);
                    }
                    &box{
                        width: 100%;
                        height: 130px;
                        background-color: var(--color-grey-21);
                    }
                }
            }
        }
    }
}