#{$ap}-{
    &buyproject{
        &__{
            &budjet{
                font-size: var(--text-sm);
                font-weight: var(--font-normal);
                color: var(--color-black-5);
                &-{
                    &cost{
                        font-size: var(--text-md);
                        font-weight: var(--font-medium);
                        color: var(--color-black);
                    }
                }
            }
            &customer{
                font-size: var(--text-md);
                font-weight: var(--font-normal);
                color: var(--color-secondary);
                &-call{
                    color: var(--color-secondary);
                    font-size: var(--text-lg);
                }
                &-num{
                    font-size: var(--text-lg);
                    font-weight: var(--font-normal);
                    color: var(--color-black-7);
                }
            }
        }
    }
}