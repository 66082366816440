#{$ap}- {
    &profile {
        &__ {
            &input {
                height: auto !important;
                border: 0 solid #ced4da;
                color: var(--color-black);
                padding: 0 !important;

                &.edit-enable {
                    padding: var(--size-3) var(--size-4) !important;
                    border: 1px solid #ced4da !important;
                }
            }
            &yelpbanner {
                width: 100%;
                &__ {
                    &img {
                        width: 100%;
                    }
                }
            }
            &col {
                &- {
                    &left {
                        width: 100%;
                        @include respond-above(md) {
                            width: 300px;
                        }
                    }
                    &right {
                        @include respond-above(md) {
                            width: calc(100% - 300px);
                            padding-left: 10%;
                        }
                    }
                }
            }
            &inwrap {
                background: none !important;
            }
            &content {
                &- {
                    &right {
                        @include respond-above(sm) {
                            padding-left: var(--size-8);
                        }
                        @include respond-above(md) {
                            padding-left: 0;
                        }
                    }
                }
            }
            &img {
                width: 200px !important;
                height: 200px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                overflow: hidden;
                background-color: #f3f3f3;

                @include respond-above(md) {
                    width: 100%;
                }
                @include respond-below(sm) {
                    width: 100%;
                    background-position: top;
                }

                &:hover {
                    & #{$ap}-profile__img-change {
                        margin-bottom: 35px;
                    }
                }

                &- {
                    &change {
                        padding: var(--size-2);
                        background-color: #ffffff;
                        opacity: 0.9;
                        display: inline-flex;
                        align-items: center;
                        border-radius: 2px;
                        margin-bottom: -50px;
                        cursor: pointer;
                        transition: all 0.2s ease;

                        &-- {
                            &icon {
                                color: var(--color-primary);
                            }
                            &label {
                                font-size: var(--text-sm);
                            }
                        }
                    }
                }
            }
            &name {
                font-size: var(--text-7xl);
                line-height: calc(var(--text-7xl) + 8px);
                font-weight: var(--font-bold);
                &- {
                    &gray {
                        font-size: var(--text-lg);
                        font-weight: var(--font-normal);
                        color: #000;
                    }
                    &text {
                        font-size: var(--text-md);
                        font-weight: var(--font-normal);
                    }
                }
            }
            &edit {
                font-size: var(--text-6xl);
                font-weight: var(--font-bold);
            }
            &image {
                width: 100%;
                height: auto;
            }
            &pro {
                width: 60px;
                font-size: var(--text-xs);
                font-weight: var(--font-bold);
                background-color: var(--color-yellow);
                border: none;
                border-radius: var(--rounded);
                color: var(--color-white);
            }
            &mail,
            &mobile {
                font-size: var(--text-md);
                line-height: calc(var(--text-xl) + 8px);
                font-weight: var(--font-medium);
            }
            &rating {
                &- {
                    &number {
                        border: 1px solid var(--color-primary);
                        font-size: var(--text-md);
                        font-weight: var(--font-medium);
                        border-radius: var(--rounded);
                    }
                    &star {
                        color: var(--color-yellow);
                    }
                    &review {
                        font-size: var(--text-md);
                        font-weight: var(--font-normal);
                        color: var(--color-grey-3);
                    }
                }
            }
            &editprofile {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                &- {
                    &btn {
                        cursor: pointer;
                        &:hover {
                            color: var(--color-secondary);

                            & > .ebw-profile__editprofile-text {
                                color: var(--color-secondary);
                            }
                        }
                    }
                    &text {
                        font-size: var(--text-xs);
                        font-weight: var(--font-medium);
                        color: var(--color-grey-4);
                    }
                }
            }
            &details {
                border-top: 1px solid var(--color-grey-5);
                // border-bottom: 1px solid var(--color-grey-5);
            }
            &one {
                width: 50%;
            }
            &number {
                &- {
                    &num {
                        font-size: var(--text-md);
                        font-weight: var(--font-semibold);
                        color: var(--color-grey);
                    }
                }
            }
            &form {
                &-input {
                    background-color: none !important;
                    border: none;
                    padding-left: 0;
                    border-radius: 0;

                    &.edit-enable {
                        border-bottom: 1px solid grey;
                    }
                }
            }

            &service {
                &- {
                    &link {
                        color: var(--color-primary);
                        font-size: var(--text-md);
                        font-weight: var(--font-normal);
                        border-bottom: 1px solid var(--color-primary);
                        display: inline-block;
                        cursor: pointer;
                    }

                    &city {
                        padding: 2px var(--size-2);
                        margin-right: var(--size-2);
                        background-color: #b3b5c2;
                        color: #ffffff;
                        font-size: var(--text-md);
                        border-radius: 100px;
                    }
                }
            }
            &modal {
                // position: relative;
                // &-close{
                //     position: absolute;
                //     top: 0;
                //     right: 10px;
                //     font-size: var(--text-xl);
                //     cursor: pointer;
                // }

                &- {
                    &head {
                        & .close {
                            // color: #ffffff !important;
                            padding: 0rem 1rem 1rem !important;
                            margin: 0 !important;
                        }
                    }
                }
            }
            &two {
                width: 50%;
            }
            &no {
                &- {
                    &num {
                        font-size: var(--text-md);
                        font-weight: var(--font-semibold);
                        color: var(--color-grey);
                    }
                }
            }
            &google {
                &- {
                    &inwrap {
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: right;

                        @media (min-width: 992px) {
                            padding-right: 160px;
                        }
                    }
                    &para {
                        color: #6a6a6a;
                        &-- {
                            &medium {
                                font-weight: 500;
                            }
                        }
                    }
                    &btn {
                        cursor: pointer;
                        color: #ffffff;
                        background-color: var(--color-primary);
                        padding: var(--size-2) var(--size-4);
                        border-radius: 5px;

                        &:hover {
                            color: #ffffff;
                        }

                        &-- {
                            &radius {
                                border-radius: 100px;
                            }
                        }
                    }
                }
            }
            // &google {
            //     width: 50%;
            // }
            // &yelp {
            //     width: 50%;
            // }
        }
    }
}

@media only screen and (max-width: 992px) {
    #{$ap}- {
        &profile {
            &__ {
                &one,
                &two {
                    width: 100%;
                }
                &service {
                    width: 40%;
                }
                &no {
                    margin-top: var(--size-8);
                }
            }
        }
    }
}

@include respond-above(md) {
    #{$ap}- {
        &profile {
            &__ {
                &image {
                    width: 300px;
                    height: auto;
                }
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
}
