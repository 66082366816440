#{$ap}- {
    &form {
        &__ {
            //- Input Elements
            &input {
                position: relative;
                font-family: var(--body-font) !important;
                //border: 1px solid #D8D8D8;
                //border-radius: 4px;
                margin-bottom: var(--size-1);
                color: var(--color-grey-3);
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                input[type="number"] {
                    -moz-appearance: textfield;
                }

                input {
                    border: 1px solid #d8d8d8 !important;
                    border-radius: 5px;
                    padding: var(--size-3) var(--size-4) !important;

                    &:focus {
                        border: 1px solid var(--color-blue-2) !important;
                        border-radius: 5px;
                        background-color: transaprent !important;
                    }

                    @include respond-above(md) {
                        //width:450px !important;
                    }
                }
                &.floating-label {
                    padding-top: 0 !important;
                }
                & > textarea {
                    border: 1px solid #d8d8d8 !important;
                    border-radius: 5px;
                    padding: var(--size-6) var(--size-4) !important;
                }
                span {
                    padding-left: var(--size-4) !important;
                    margin-top: calc(var(--size-1) - 3px) !important;
                }

                &- {
                    &select {
                        height: 56px;

                        &-- {
                            &category {
                                border-right: none !important;

                                &:focus {
                                    border: 1px solid var(--color-grey-20) !important;
                                    border-right: none !important;
                                }
                            }
                            &search {
                                width: 100%;
                                position: absolute;
                                top: 54px;
                                z-index: 10;
                                background-color: var(--color-grey-20);
                                color: var(--color-grey-6);
                                border: 1px solid #e6e6e6;

                                & div {
                                    padding: 10px 20px;
                                    border-bottom: 1px solid #e6e6e6;
                                    background: #f8f8f8;
                                    cursor: pointer;
                                    transition: all 0.2s ease;

                                    &:hover {
                                        background: #e5e5e5;
                                    }

                                    &:last-child {
                                        border-bottom: 0px;
                                    }
                                }
                            }
                            &badge {
                                background-color: var(--color-grey-20);
                                color: var(--color-grey-6);
                                padding: var(--size-2) var(--size-4);
                                margin: 0 var(--size-2) var(--size-3) 0;
                            }
                            &add {
                                background-color: var(--color-blue-2);
                                color: var(--color-white);
                                width: 100%;
                                height: 100%;
                                padding: var(--size-3) 0;
                                text-align: center;
                                border-radius: 6px;

                                &:hover {
                                    background-color: #1d8cff;
                                    color: #fff;
                                }
                            }
                        }
                    }
                    &payment {
                        height: 40px;
                    }
                    &show {
                        &-- {
                            &password {
                                top: 16px;
                                right: 20px;
                                z-index: 20;
                                cursor: pointer;
                                user-select: none;
                                background-color: var(--color-white);
                                padding: 0 var(--size-2);
                                z-index: 10;
                            }
                            &text {
                                color: var(--color-grey-8);
                                font-size: var(--text-sm);
                            }
                        }
                    }
                    &otp {
                        /*&.floating-label input{
                            text-align: center !important;
                            border-bottom:2px solid var(--color-grey-9) !important;
                            color:var(--black);
                            font-weight: var(--font-semibold);

                            &:focus{
                                border-bottom:2px solid var(--color-primary) !important;
                            }
                        }*/

                        border-right: none !important;
                        border-left: none !important;
                        border-top: none !important;
                        border-bottom: 2px solid var(--color-grey-20) !important;
                        color: var(--black);
                        font-weight: var(--font-semibold);
                        width: var(--size-6) !important;
                        margin-right: var(--size-2);
                        user-select: none !important;
                        background: none;

                        &:focus {
                            border-bottom: 2px solid var(--color-primary) !important;
                        }
                    }

                    //- Input Text with No border for Edits
                    &noborder {
                        border: none;
                        position: relative;
                        z-index: var(--z-5);
                    }

                    //- Custom Input File in My Works Pages
                    &file {
                        top: 0;
                        width: calc(var(--size-12) * 5);
                        height: var(--size-12);
                        z-index: var(--z-20);
                        opacity: 0;
                        cursor: pointer;

                        &-- {
                            &custom {
                                background-color: var(--color-lightblue);
                                color: var(--color-white);
                                width: 100%;
                                height: 100%;
                                padding: var(--size-3) var(--size-6);
                                margin: 0 auto;
                                box-shadow: 0px 3px 6px #00000029;
                                border-radius: 2px;
                                border: 0;
                                z-index: var(--size-1);

                                &:hover,
                                &:not(.disabled):active {
                                    background-color: var(--color-secondary) !important;
                                }

                                @include respond-above(sm) {
                                    width: calc(var(--size-12) * 5);
                                    height: var(--size-12);
                                }
                                @include respond-below(sm) {
                                    width: 220px;
                                    padding: var(--size-2) var(--size-6);
                                }

                                img {
                                    width: var(--size-4);
                                    height: var(--size-4);
                                }
                            }
                            &text {
                                font-size: var(--text-xl);
                                letter-spacing: 0.52;
                            }

                            //- Chat Conversation in Project-Inconversation page
                            &conversation {
                                width: var(--size-8) !important;
                                height: var(--size-8) !important;
                                cursor: pointer;
                            }
                            &group {
                                border: 1px solid #bababa;
                                border-radius: 4px;
                                padding: calc(var(--size-1) - 3px);
                            }
                            &upload {
                                padding: var(--size-1) var(--size-3);

                                @include respond-below(sm) {
                                    padding: var(--size-1) var(--size-2);
                                }
                            }
                        }
                    }

                    &search {
                        margin-bottom: 0 !important;

                        &- {
                            &wrap {
                                width: 100%;
                                margin: 10px 0;

                                @media (min-width: 992px) {
                                    width: calc(100% - 700px) !important;
                                    padding: 0 20px;
                                    margin: 0;
                                }

                                // @media (min-width: 1320px) {
                                //     width: 600px !important;
                                // }
                            }
                        }

                        & input {
                            // padding: 7px !important;

                            & + span {
                                // padding: 5px 0 13px 0;
                            }
                        }
                    }
                }
            }

            //- Checkboxes Elements
            &checkbox {
                &- {
                    &label {
                        cursor: pointer;
                        color: var(--color-grey-8);
                    }
                    &text {
                        font-size: var(--text-sm);
                        color: var(--color-grey-8);
                    }
                }
            }

            //- Checboxes with icons in Register Page
            &checkboxes {
                width: 100%;
                margin: 0 auto;
                display: block;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                &- {
                    &inline {
                        width: auto;
                        margin: unset;
                    }
                }

                & input:checked ~ .ebw-form__checkboxes-mark {
                    // background-color: rgba(#6fb2fe54, 0.35);
                    background: var(--color-primary);
                }
                & input:checked ~ .ebw-form__checkboxes-mark:after {
                    display: block;
                    opacity: 1;
                }
                & .ebw-form__checkboxes-mark:after {
                    left: 7px;
                    top: 4px;
                    width: 5px;
                    height: 10px;
                    border: solid #ffffff;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                &- {
                    &info {
                        @include respond-below(lg) {
                            height: 95px;
                        }
                    }
                    &img {
                        width: var(--size-7);
                        height: var(--size-7);
                    }
                    &text {
                        font-size: var(--text-xs);
                        color: var(--color-darkblue);
                        font-weight: var(--font-semibold);
                    }

                    &check {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }
                    &mark {
                        position: absolute;
                        bottom: auto;
                        left: auto;
                        height: 20px;
                        width: 20px;
                        margin: 0 auto;
                        margin-left: -8px;
                        background-color: transparent;
                        border: 1px solid #e3e3e3;
                        border-radius: 2px;
                        cursor: pointer;

                        &:after {
                            content: "";
                            position: absolute;
                            border: solid var(--color-primary);
                            opacity: 0;
                        }

                        &-- {
                            &category {
                                right: 0;
                                left: unset;
                            }
                        }
                    }
                }
            }

            //- Custom Input in Project Detail Page
            &bid {
                &- {
                    &input {
                        border: 0.5px solid #e8e8e8;
                        border-radius: 3px;
                        background-color: var(--color-grey-21);

                        &::-webkit-input-placeholder {
                            font-weight: var(--font-normal);
                            font-size: var(--text-sm);
                            color: var(--color-grey-18);
                        }
                    }
                }
            }
        }
    }

    //- Form Error Messages
    &input {
        position: relative;
        &__ {
            &error {
                // background-color: var(--color-white);
                color: var(--color-danger);
                font-size: var(--text-sm);
                // position: absolute;
                // bottom: 5px;
                // left: 15px;
                // margin-bottom: 0;
                padding: 0 var(--size-1);
                text-align: left;

                @include respond-above(sm) {
                    left: 28px;
                }
                &-- {
                    &service-list {
                        top: 23px;
                        left: 120px;
                        bottom: unset;
                    }
                }
            }
            &option {
                &- {
                    &edit {
                        top: 0;
                        bottom: 0;
                        right: 0;
                        display: flex;
                        align-items: center;
                        padding-right: 30px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

input:-webkit-autofill {
    background: transparent !important;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
    background: transparent !important;
}
input:-internal-autofill-selected {
    background: transparent !important;
}
