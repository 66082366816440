// LOGIN & REGISTRATION

#{$ap}- {
    &entry {
        &__ {
            &container {
                &-- {
                    &login {
                        @include respond-above(sm) {
                            height: 100%;
                        }
                        @include respond-above(lg) {
                            min-height: inherit;
                            padding: 0 120px;
                        }
                    }
                }
            }
            &row {
                min-height: calc(100vh - 75px);

                @include respond-above(sm) {
                    width: 100%;
                }

                &-- {
                    &login {
                        @include respond-above(sm) {
                            min-height: inherit;
                            padding: 50px 0;
                            background: white;
                            box-shadow: 0 10px 12px #0000000d;
                        }
                    }
                }
            }
            &col {
                &- {
                    &lft {
                        // box-shadow: 10px 0px 20px #97979729;
                        // height: calc(100vh - 75px);
                        padding: 0 var(--size-3);

                        @include respond-above(md) {
                            padding: 0;

                            &::before {
                                content: "";
                                position: absolute;
                                right: 0;
                                width: 1px;
                                height: 100%;
                                top: 0;
                                bottom: 0;
                                background: rgba(112, 112, 112, 0.28);
                            }
                        }
                    }
                    &rgt {
                        padding: 0;
                        // padding-top: var(--size-16);
                    }
                }
            }
            &slider {
                &- {
                    &container {
                        padding-bottom: 50px !important;
                    }
                }
            }
            &content {
                &- {
                    &lft {
                        @include respond-above(md) {
                            padding: 0 50px;
                        }
                    }
                    &rgt {
                        @include respond-above(md) {
                            padding: 0 100px 0 50px;
                        }
                    }
                    &slider {
                        background-color: #ffffff;
                        padding: 0 var(--size-3) var(--size-4);

                        @include respond-above(md) {
                            padding: 0;
                        }
                    }
                    &login {
                        background-color: #ffffff;
                    }
                    &wrap {
                        // padding-top: var(--size-16);
                        height: 100%;
                        // position: fixed;
                        // left: 0;
                        // top:80px;
                        // width: 33.33%;
                        // overflow: hidden;
                        @include respond-below(md) {
                            //   background-color: var(--color-white);
                        }

                        @include respond-below(md) {
                            position: relative;
                            width: 100%;
                            // padding-bottom: var(--size-14);
                        }
                    }
                    &top {
                        // padding: 0 var(--size-4);
                        // margin-bottom: var(--size-6);

                        @include respond-above(lg) {
                            // padding: 0 var(--size-8);
                            // padding: 0 calc(var(--size-12) + var(--size-5));
                        }
                        @include respond-below(sm) {
                            // padding: 0 var(--size-4);
                        }
                    }
                    &title {
                        font-size: var(--text-xl);
                        color: var(--color-grey-6);
                        margin-bottom: var(--size-2);
                        padding-right: var(--size-1);
                        position: relative;

                        @include respond-above(lg) {
                            // padding-right: var(--size-12);
                        }

                        // &:after {
                        //     content: "";
                        //     position: absolute;
                        //     left: 0px;
                        //     width: calc(var(--size-12) + var(--size-3));
                        //     height: 2px;
                        //     background-color: rgba(#264eef, 0.55);
                        //     margin-top: var(--size-8);

                        //     @include respond-above(lg) {
                        //         // left: 85px;
                        //     }

                        //     @include respond-below(sm) {
                        //         // left: 28px;
                        //     }
                        // }
                    }
                    &para {
                        font-size: var(--text-md);
                        color: rgba(0, 0, 0, 0.53);
                        opacity: 0.78;
                        padding-right: var(--size-1);

                        @include respond-above(lg) {
                            padding-right: var(--size-12);
                        }
                    }
                    &bottom {
                        height: 100%;
                    }
                    &img {
                        &-- {
                            &screens {
                                position: relative;
                                // margin-bottom: -70px;
                                // padding: 0 var(--size-2);
                                z-index: 1;

                                @include respond-above(lg) {
                                    // padding: 0 calc(var(--size-12) + var(--size-2));
                                }
                            }
                        }
                    }
                    &btn {
                        font-size: var(--text-md);
                        &-- {
                            &icon {
                                font-weight: bold;
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }
            &nav {
                border-bottom: none !important;
                margin-bottom: var(--size-4);

                &- {
                    &item {
                        a {
                            border: 1px solid #e0e0e0 !important;
                            border-radius: 0 !important;
                            padding: var(--size-2) var(--size-5);
                            cursor: pointer;

                            @include respond-below(sm) {
                                padding: var(--size-2) var(--size-4);
                            }
                            @media (max-width: 350px) {
                                padding: var(--size-2) var(--size-2);
                                font-size: var(--text-sm);
                            }

                            &.active {
                                border: 1px solid var(--color-lightblue) !important;
                                box-shadow: 0px 3px 6px #80808029;
                                color: var(--color-lightblue) !important;
                                background: #f2f8ff !important;
                            }
                        }

                        &:first-child {
                            & a {
                                border-top-left-radius: 5px !important;
                                border-bottom-left-radius: 5px !important;
                            }
                        }
                        &:last-child {
                            & a {
                                border-top-right-radius: 5px !important;
                                border-bottom-right-radius: 5px !important;
                            }
                        }
                    }
                }
            }
            &title {
                font-weight: 500;
                color: #3e3939;
                &- {
                    &top {
                        font-size: 36px;
                        color: #3e3939;
                        margin-bottom: var(--size-1);
                    }
                    &bottom {
                        font-size: 18px;
                    }
                }
            }
            &tab {
                &- {
                    &content {
                        min-height: 338px;
                    }
                    &title {
                        font-size: var(--text-lg);
                        margin-bottom: var(--size-6);
                        color: var(--color-grey-8);
                        letter-spacing: 0.29px;
                        font-weight: var(--font-medium);
                    }
                    &forgot {
                        color: var(--color-primary);
                        font-weight: var(--font-medium);

                        &:hover {
                            color: var(--color-black);
                        }

                        &-- {
                            &link {
                                color: var(--color-primary);
                                font-weight: var(--font-medium);

                                &:hover {
                                    color: var(--color-black);
                                }
                            }
                        }
                    }
                }
            }
            //-Login with OTP Section
            &otp {
                margin-top: var(--size-8);

                @include respond-below(sm) {
                    margin-top: var(--size-4);
                }

                &- {
                    &title {
                        font-size: var(--text-sm);
                        font-weight: var(--font-medium);
                        color: var(--black);
                    }
                }
            }

            &other {
                &- {
                    &option {
                        width: 45px;
                        height: 45px;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #ffffff !important;
                        // padding: 10px 30px;
                        border: 1px solid #e5e5e5 !important;
                        border-radius: 5px;
                        transition: all 0.2s ease;

                        &:hover {
                            & svg path {
                                fill: #ffffff;
                            }
                        }

                        // @include respond-above(lg) {
                        //     width: 210px;
                        // }

                        & img {
                            height: 20px;
                            // margin-right: 10px;
                        }

                        &s {
                            margin-top: var(--size-6);
                        }

                        &-- {
                            &facebook {
                                border: none;
                                background-color: transparent;
                                border-color: #264eef !important;

                                &:hover {
                                    background: #264eef !important;
                                }
                            }
                            &google {
                                border: none;
                                background-color: transparent;
                                border-color: #e33030 !important;

                                &:hover {
                                    background: #e33030 !important;
                                }
                            }
                            &apple {
                                border: none;
                                background-color: transparent;
                                border-color: #000000 !important;

                                &:hover {
                                    background: #000000 !important;
                                }
                            }
                        }
                    }
                    &text {
                        // color: var(--color-grey-8);
                        color: #3e3939;
                        opacity: 0.75;
                        line-height: 1.2;
                    }
                    &register {
                        margin-top: var(--size-8);
                    }
                    &account {
                        font-size: var(--text-sm);
                        color: var(--color-grey-8);
                    }
                    &create {
                        font-size: var(--text-sm);
                        color: var(--color-primary);
                        font-weight: var(--font-semibold);
                    }
                }
            }

            &pagination {
                &- {
                    &bullet {
                        width: 10px;
                        height: 10px;
                        // background: #dcdcdc;
                        margin-right: var(--size-1);

                        &.swiper-pagination-bullet-active {
                            background: var(--color-secondary);
                        }
                    }
                }
            }

            //- Login - Register Mobile Slider
            &login,
            &register {
                &- {
                    &slide {
                        width: 100%;
                        height: 100%;
                    }
                    &paginationbullets {
                        left: 0px !important;
                        bottom: 0;
                    }
                    &bullets {
                        width: 25px !important;
                        height: 6px !important;
                        background-color: var(--color-grey-8) !important;
                        border-radius: 4px !important;
                        margin-right: var(--size-1);
                        margin-top: var(--size-8);
                    }
                }
            }

            //- Register Page
            &register {
                &- {
                    &title {
                        font-size: var(--text-lg);
                        color: var(--color-grey-7);
                        //margin-bottom: var(--size-4);
                        letter-spacing: 0.29px;
                        font-weight: var(--font-normal);
                        opacity: 0.75;
                    }
                    &flow {
                        //padding:0 var(--size-12);
                    }
                    &list {
                        /*&:before{
                            content:"";
                            position:absolute;
                            top:10px;
                            left:0;
                            right:0;
                            margin:0 auto;
                            width:85%;
                            height:1px;
                            background-color: #EBEBEB;

                            @include respond-above(sm){
                                width:93%;
                            }
                            @include respond-above(md){
                                width:90%;
                            }
                        }*/
                        a,
                        span {
                            line-height: 30px !important;
                        }
                        a:not([href]):not([class]),
                        div {
                            font-size: var(--text-sm) !important;
                            font-weight: var(--font-normal) !important;
                            line-height: 30px !important;

                            @include respond-below(sm) {
                                font-size: var(--text-xs) !important;
                                font-weight: var(--font-medium) !important;
                            }
                        }
                    }
                    &item {
                        position: relative;

                        @include respond-above(sm) {
                            min-width: 60px;
                        }
                        @include respond-above(md) {
                            min-width: 60px;
                        }
                    }
                    &dots {
                        width: 20px;
                        height: 20px;
                        margin-bottom: var(--size-1);
                        background-color: var(--color-grey-9);
                        color: var(--black);
                        border: 1px solid var(--color-grey-9);
                        border-radius: var(--rounded-full);
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        font-size: var(--text-sm);

                        &.active {
                            background-color: var(--color-primary);
                        }
                    }
                    &step {
                        font-size: var(--text-sm);
                        color: var(--color-grey-2);
                    }
                    &form {
                        @include respond-above(lg) {
                            //padding:0 var(--size-10);
                        }
                    }
                    &terms {
                        &-- {
                            &text {
                                color: var(--color-grey-4);
                                font-size: var(--text-sm);
                            }
                            &link {
                                color: var(--color-primary);
                                font-weight: var(--font-semibold);
                                font-size: var(--text-sm);

                                &:hover {
                                    color: var(--black);
                                    text-decoration: underline;
                                }
                            }
                            &verify {
                                color: var(--color-primary);
                                font-size: var(--text-sm);
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            //- Forgot Password
            &forgot {
                &- {
                    &title {
                        font-size: var(--text-xl);
                        color: var(--color-grey-2);
                        font-weight: var(--font-medium);
                    }
                    &tag {
                        font-size: var(--text-sm);
                        color: var(--color-grey-3);
                    }
                }
            }

            //- icon
            &icon {
                &-- {
                    &add {
                        transform: rotate(45deg);
                        transition: all 0.2s ease;
                        & span {
                            font-weight: 600;
                        }

                        &.icon-close {
                            transform: rotate(0deg);
                            & span {
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }

            //- Services
            &service {
                &- {
                    &main {
                        cursor: pointer;
                        &.open {
                            & + #{$ap}-entry__service-subcategories {
                                max-height: 600px;
                                transition: all 0.8s linear;
                                transition-delay: 0s;
                            }
                        }
                    }
                    &subcategories {
                        // height: 0;
                        // max-height: 0;
                        overflow: hidden;
                        border-radius: 5px;
                        background-color: #ffffff;
                        box-shadow: 0px 3px 6px rgba(119, 119, 119, 0.16);
                    }
                    &subcategory {
                        margin-bottom: var(--size-2);
                        cursor: pointer;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                &s {
                    &- {
                        &error {
                            padding: 10px;
                            background: #ff000026;
                            color: red;
                            border-radius: 10px;
                            border: 1px solid red;
                        }
                    }
                }
            }

            //- Label
            &label {
                &- {
                    &services {
                        color: var(--color-grey-10);
                        font-size: var(--text-sm);
                    }
                    &main {
                        font-size: var(--text-xl);
                        text-align: left;
                    }
                    &subcategory {
                        color: var(--color-grey-10);
                        font-size: var(--text-md);
                        cursor: pointer;
                    }
                }
            }

            //- Card Styles for PRO+ Page
            &pro {
                &- {
                    &card {
                        width: 100%;
                        // width:400px;
                        background: #ffffff 0% 0% no-repeat padding-box;
                        box-shadow: 0px 0px 60px #90b2e133;
                        border: 1px solid #e7e7e7;
                        border-radius: 25px;
                        padding: var(--size-6) var(--size-6) var(--size-6);
                        @media (max-width: 767px) {
                            padding: 15px;
                        }
                        @media (min-width: 768px) {
                            padding: var(--size-8) var(--size-8) var(--size-8);
                        }

                        &-- {
                            &full-width {
                                width: 100%;
                            }
                        }
                    }
                    &title {
                        font-size: var(--text-lg);
                        color: #646464;
                        letter-spacing: 0.29px;
                        font-weight: var(--font-medium);
                    }
                    &plan {
                        // background: rgba(#FE8F06, 0.28);
                        box-shadow: 0px 0px 1px #00000029;
                        border-radius: 10px;
                        padding: var(--size-8) var(--size-8);
                        margin: var(--size-5) 0 var(--size-7);
                        position: relative;
                        background-size: cover;
                        background-repeat: no-repeat;
                        overflow: hidden;

                        @media (min-width: 992px) {
                            width: 380px;
                        }

                        // &::before {
                        //     content: "";
                        //     position: absolute;
                        //     left: 0;
                        //     top: 0;
                        //     width: 100%;
                        //     height: 100%;
                        //     background: transparent linear-gradient(105deg, #F4B94B 0%, #FE8F06 100%) 0% 0% no-repeat padding-box;
                        //     opacity: 0.33;
                        // }

                        &-- {
                            &title {
                                font-size: var(--text-sm);
                                font-weight: var(--font-medium);
                                color: #272522;
                            }
                            &oldprice {
                                letter-spacing: 0.38px;
                                color: #373737;
                                opacity: 0.45;
                                font-size: var(--text-xl);
                                font-weight: var(--font-normal);
                                text-decoration: line-through;
                            }
                            &actualprice {
                                font-size: var(--text-6xl);
                                font-weight: var(--font-medium);
                                letter-spacing: 0.64px;
                                color: #373737;
                            }
                            &annual {
                                color: #8d8d8d;
                                opacity: 0.45;
                                font-size: var(--text-sm);
                                font-weight: var(--font-normal);
                            }
                        }
                    }
                    &payment {
                        // border-top: 1px solid #E7E7E7;
                        // padding: 0 var(--size-5);

                        &-- {
                            &title {
                                font-size: var(--text-md);
                                color: #2a2a2a;
                                font-weight: var(--font-bold);
                            }
                        }
                    }
                    &info {
                        color: #2a2a2a;
                        opacity: 0.2;
                    }
                }
            }
        }
    }
}
