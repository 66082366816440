#{$ap}-{
    &myprojects{
        &__{
            &blue{
                background-color: var(--color-primary) !important;
                border-radius: var(--rounded);
                color: var(--color-white) !important;
                border: none;
            }
            &navlink{
                border: none !important;
                cursor: pointer;
                color: var(--color-grey-8);
            }
        }
    }
}