#{$ap}- {
    &prodet {
        &__ {
            &container {
                @media (min-width: 1500px) {
                    max-width: 70% !important;
                }
            }
            &inwrap {
            }
            &back {
                &- {
                    &link {
                        @include respond-below(sm) {
                            margin-bottom: var(--size-3);
                        }
                    }
                }
            }
            &row {
                // border-bottom: 2px solid var(--color-grey-14);
            }
            &angle {
                color: var(--color-primary);
            }
            &text {
                font-size: var(--text-lg);
                font-weight: var(--font-normal);
                color: var(--color-grey-15);
            }
            &title {
                font-size: var(--text-3xl);
                font-weight: var(--font-bold);
                color: var(--color-black);
                &-project {
                    font-size: var(--text-lg);
                }
            }
            &more {
                &-text {
                    width: 100px;
                    background-color: var(--color-grey-16);
                    font-size: var(--text-lg);
                    font-weight: var(--font-normal);
                    color: var(--color-black-6);
                    padding: var(--size-2) var(--size-3);
                }
                &-icon {
                    color: var(--color-blue-2);
                }
            }
            /*&round{
                &-{
                    &blue{
                        width: 3px;
                        height: 3px;
                        background-color: var(--color-blue-2);
                        border-radius: var(--rounded);
                        margin-left: var(--size-1);
                    }
                }
            }*/
            &btn {
                &- {
                    &icon {
                        right: 15px;
                        top: 0px;
                        bottom: 0;
                        margin: auto;
                        font-weight: 600;
                    }
                    &count {
                        background-color: #f99c00;
                        margin-left: 10px;
                        &:hover {
                            color: black;
                        }
                    }
                }
            }
            &customer {
                &- {
                    &profile {
                        @include respond-above(md) {
                            width: 50%;
                            height: 100%;
                            padding-right: 30px;
                        }

                        @include respond-below(sm) {
                            margin-bottom: var(--size-8);
                        }
                    }
                }
            }
            &profile {
            }
            &slider {
                // margin: 30px 0;
                margin-top: 30px;

                @include respond-above(xl) {
                    width: 50%;
                }

                @media (min-width: 992px) {
                    margin-top: 0;
                }
            }
            &img {
            }
            &details {
            }
            &name {
                font-size: var(--text-lg);
                font-weight: var(--font-normal);
                color: var(--color-primary);
            }
            &content {
                // box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.08);
                // padding-bottom:var(--size-8);

                &- {
                    &wrap {
                    }
                }
            }
            &date {
            }
            &subtitle {
            }
            &all {
                // border-top: 2px solid var(--color-grey-14);
            }
            &bid {
                font-size: var(--text-md);
                line-height: calc(var(--text-md) + 10px);
                background-color: var(--color-secondary) !important;
                border: none;
                border-radius: var(--rounded);
                padding: var(--size-2) var(--size-6);
                color: var(--color-white) !important;
                cursor: pointer;

                &:hover {
                    background-color: var(--color-secondary) !important;
                }
                &:after {
                    font-family: "icomoon";
                    position: absolute;
                    right: 15px;
                    top: 12px;
                    color: var(--color-white);
                    font-size: var(--text-lg);
                }
                &:focus {
                    background-color: var(--color-secondary) !important;
                }
                &- {
                    &image {
                        width: 20px;
                        height: 20px;
                    }
                    &collapse {
                    }
                    &arrow--up {
                        &:after {
                            font-family: "icomoon";
                            content: "\e904";
                        }
                    }
                    &arrow--down {
                        &:after {
                            font-family: "icomoon";
                            content: "\e900";
                        }
                    }
                }
                &detail {
                    font-size: var(--text-xs);
                    font-weight: var(--font-normal);
                    color: var(--color-black);
                }
            }
            &ignore {
                background-color: var(--color-white) !important;
                color: var(--color-black);
                border: none;
                display: flex;
                align-items: center;
                border: 1px solid var(--color-grey-18);
                width: 100%;
                :hover {
                    color: var(--color-black);
                }
                :focus {
                    color: var(--color-black);
                }
                :after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 15px;
                    margin-right: var(--size-4);
                }
            }
            &ask {
                background-color: var(--color-white) !important;
                color: var(--color-black);
                border: none;
                display: flex;
                align-items: center;
                border: 1px solid var(--color-primary);
                width: 100%;
                :hover {
                    color: var(--color-black) !important;
                }
                :focus {
                    color: var(--color-black) !important;
                }
                :after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 15px;
                    margin-right: var(--size-4);
                }
            }
            &dropdown {
                box-shadow: 0 8px 6px -6px black;
            }
            &total {
                font-size: var(--text-xxs);
                font-weight: var(--font-normal);
            }
            &description {
                font-size: var(--text-sm);
                font-weight: var(--font-light);
                color: var(--color-black-5);
            }
            &dimension {
                font-size: var(--text-sm);
                font-weight: var(--font-medium);
                color: var(--color-black);
                &- {
                    &grey {
                        font-size: var(--text-sm);
                        color: var(--color-grey-17);
                        // padding-left: 20px;

                        @include respond-above(sm) {
                            // text-align: right;
                        }
                    }

                    &wrap {
                        @include respond-above(md) {
                            // padding-right: 50px;
                        }
                    }

                    &qa {
                        @include respond-above(sm) {
                            width: 60%;
                        }
                    }
                    &answer {
                        @include respond-above(sm) {
                            width: 40%;
                            padding-left: 30px;
                        } 
                    }
                }
            }
            &qa {
                width: 100%;
                &- {
                    &wrap {
                        @include respond-above(md) {
                            padding-right: 50px;
                        }
                    }
                    &inwrap {
                        margin-bottom: var(--size-4);

                        @include respond-above(sm) {
                            margin-bottom: var(--size-2);
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &show {
                font-size: var(--text-sm);
                font-weight: var(--font-normal);
                color: var(--color-primary);
            }
            &roof {
                width: 100%;
            }
            &roofscope {
                &-text {
                    background-color: var(--color-lightgreen-2);
                    border: 1px solid var(--color-lightgreen);
                    color: var(--color-grey-6);
                    font-weight: var(--font-light);
                    font-size: var(--text-xs);

                    &-- {
                        &red {
                            background-color: var(--color-lightred-2);
                            border: 1px solid var(--color-lightred);
                            color: var(--color-red);
                        }
                    }
                }
            }
            &file {
                &-icon {
                    width: 10px;
                    height: auto;
                }
            }
            &map {
                width: 100%;
                height: auto;
            }

            &number {
                font-size: var(--text-lg);
                border-bottom: 1px dotted #707070;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    #{$ap}- {
        &prodet {
            &__ {
                &roofscope {
                    width: 350px;
                }
                &map {
                    width: 350px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .ebw-sticky__project-details {
        position: relative !important;
        top: unset !important;
        margin-top: 0 !important;
    }
}

.ebw-sticky__wrap {
    margin-top: 30px;

    @media (min-width: 992px) {
        position: absolute;
        margin-top: 0px;
    }
}
