#{$ap}- {
    &paymentn {
        &__ {
            &row {
                margin-bottom: em(20);
            }
            &title {
                font-size: var(--text-lg);
                font-weight: 600;
                color: #0EB964;
                border-bottom: 1px dotted #707070;
                padding-right: var(--size-6);
                padding-bottom: 3px;

                &-- {
                    &M {
                        @media (min-width: 767px) {
                            font-size: var(--text-6xl);
                        }
                    }
                }
            }
            &amount {
                font-size: var(--text-lg);
                color: #000000;
                font-weight: 600;

                &-- {
                    &M {
                        @media (min-width: 767px) {
                            font-size: var(--text-6xl);
                        }
                    }
                }
            }
            &para {
                font-size: var(--text-xs);
                color: #585858;

                &-- {
                    &M {
                        font-size: var(--text-lg);
                    }
                }
            }
            &label {
                // font-size: var(--text-sm);
                // font-weight: 600;
            }

            &card {
                // box-shadow: 0px 0px 5px #6c6c6c21;
                border: 0.6000000238418579px solid #E5E5E5;
                border-radius: 5px;

                &- {
                    &inwrap {
                        padding: var(--size-3) var(--size-4) var(--size-3) var(--size-10);
                    }
                    &type {
                        &-- {
                            &img {
                                width: 40px;

                                & img {
                                    width: 100%;
                                }
                            }
                            &num {
                                font-size: var(--text-sm);
                            }
                        }
                    }
                    &save, &mmyy {
                        font-size: var(--text-sm);
                    }

                    &input {
                        & + #{$ap}-paymentn__card-inwrap {
                            position: relative;
                            cursor: pointer;

                            &::before {
                                content: '';
                                position: absolute;
                                left: 15px;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                width: 18px;
                                height: 18px;
                                border: 1px solid #1E05F9;
                                opacity: 0.2;
                                border-radius: 100%;
                                background: #fff;
                            }
                            &::after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                background: #1E05F9;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                                left: 18px;
                                border-radius: 100%;
                                opacity: 0;
                                transition: all 0.2s ease;
                            }
                        }
                        &:checked {
                            & + #{$ap}-paymentn__card-inwrap {
                                &::after, &::before {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    &new {
                        border-radius: 5px;
                        border: 0.6000000238418579px solid #E5E5E5;
                        padding: 10px 10px;
                    }
                }
            }

            &add-card {
                position: relative;
                padding: 15px;
                background: #F5F6FA;
                border-radius: 50px;
                font-size: 14px;
                font-weight: 800;
                cursor: pointer;
            }
        }
    }
}