#{$ap}- {
    &modal {
        &__ {
            &body {
                padding-bottom: 0;
            }
            &wrap {
                position: relative;
                padding-top: var(--size-6) !important;
                padding-bottom: var(--size-6) !important;
                border-radius: 5px;
                overflow: hidden;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 5px;
                    background-color: var(--color-secondary);
                }

                &-- {

                    &danger {
                        &::before {
                            background-color: var(--color-red) !important;
                        }
                    }
                }

                & button.close {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
            }
            &title {
                font-size: var(--text-lg);
                line-height: calc(var(--text-lg) + 5px);
                font-weight: var(--font-medium);
                color: #616161;
            }
            &para {
                font-size: var(--text-sm);
                font-weight: var(--font-normal);
                line-height: calc(var(--text-sm) + 8px);
                color: #838383;

                &-- {
                    &bold {
                        font-size: var(--text-lg);
                        line-height: calc(var(--text-lg) + 10px);
                        font-weight: 500;
                    }
                }
            }
            &input {
                padding: var(--size-6) var(--size-3);
            }
            &textarea {
                min-height: 120px;
            }
            &submit {
                border: none;
                padding: var(--size-2) var(--size-8);
                border-radius: 2px;
            }
            &videoplayer{
                width:100%;
                height:auto;
                background-color: var(--color-black);
                color:var(--color-white);

                & .text-wrap {
                    padding: 30px;
                    word-break: break-all;
                }

                & .video-react {
                    width: auto !important;
                    height: 65vh !important;
                    padding-top: 0 !important;
                    margin-bottom: 15px;
                }
                & .video-react .video-react-big-play-button {
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
                &-{
                    &wrap {
                        height: 100%;
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    &modal{
                        @include respond-above(md){
                            max-width:800px !important;
                            background-color: var(--color-black);
                        }
                    }
                }
            }
        }
    }
}