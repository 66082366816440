#{$ap}- {
    &support {
        &__ {
            &title {
                font-size: calc(var(--text-3xl) + 3px);

                @include respond-above(md) {
                    font-size: var(--text-4xl);
                }

                &-- {
                    &terms {
                        border-bottom: 1px solid rgba(#707070, 0.11);
                    }
                }
            }
            &card {
                background-color: var(--color-white);
                padding: var(--size-8) var(--size-6);
                box-shadow: 0px 3px 30px #c0c0c029;
                border: 1px solid #ededed;
                border-radius: 10px;

                @include respond-below(sm) {
                    margin-top: var(--size-5);
                }

                &s {
                    background-color: var(--color-white-1);
                    padding: var(--size-6) var(--size-4);

                    @include respond-above(md) {
                        padding: var(--size-12) var(--size-8);
                    }
                }

                &- {
                    &lft {
                        @include respond-above(sm) {
                            margin-bottom: var(--size-5);
                        }
                    }
                    &support {
                        letter-spacing: 0.32px;
                        font-weight: var(--font-medium);
                        font-size: var(--text-lg);
                    }
                    &available {
                        letter-spacing: 0.99px;
                        font-size: calc(var(--text-6xl) * 2);
                        font-weight: var(--font-medium);
                        color: var(--color-primary);

                        @include respond-below(sm) {
                            font-size: calc(var(--text-4xl) * 2);
                        }
                    }
                    &icon {
                        color: var(--color-primary);
                        overflow: hidden;
                        margin-bottom: var(--size-5);
                    }
                    &title {
                        letter-spacing: 0.32px;
                        font-size: var(--text-lg);
                        font-weight: var(--font-medium);
                        color: var(--color-black-2);
                    }
                    &desc {
                        font-size: var(--text-md);
                        color: var(--color-black-2);
                        letter-spacing: 0.32px;

                        &:hover {
                            color: var(--color-primary);
                        }
                    }
                }
            }

            &frequent {
                &- {
                    &title {
                        color: var(--color-black-2);
                        font-size: var(--text-lg);
                        font-weight: var(--font-medium);
                        font-size: var(--text-sm);
                        font-weight: var(--font-medium);
                        color: var(--color-black);
                    }
                    &inwrap {
                        padding: var(--size-4);
                        margin-bottom: var(--size-3);

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:nth-child(2n) {
                            background-color: var(--color-white);
                            border-radius: 2px;
                        }

                        @include respond-above(lg) {
                            padding: var(--size-5);
                        }
                    }
                    &qstn {
                        color: var(--color-black-2);
                        font-size: var(--text-lg);
                        font-weight: var(--font-medium);
                        letter-spacing: 0.32px;
                    }
                    &ans {
                        color: var(--color-black-5);

                        @include respond-above(lg) {
                            padding-right: calc(var(--size-10) * 8);
                        }
                    }
                }
            }
        }
    }

    &article {
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }

        &__ {
            &title {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 15px;

                @media (min-width: 768px) {
                    font-size: 24px;
                }
            }
            &subtitle {
                font-size: 16px;
                font-weight: 800;
                margin-bottom: 5px;
                color: #626262;

                @media (min-width: 768px) {
                    font-size: 18px;
                }
            }
        }
    }
}
