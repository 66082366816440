#{$ap}- {
    &date {
        &__ {
            &selected {
                padding: 5px 10px;
                background-color: #eaeaea;
                color: #000000;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
}