#{$ap}-{
    &buyprojects{
        &__{
            &budjet{
                font-size: var(--text-sm);
                font-weight: var(--font-normal);
                color: var(--color-black-5);
            }
            &cost{
                font-size: var(--text-sm);
                font-weight: var(--font-medium);
                color: var(--color-black);
            }
        }
    }
}