#{$ap}- {
    &project{
        &__{
            &main{
                &-{
                    &swiper{
                        width:100%;
                        height:40vh;
                    }
                    &slider{
                        width:100% !important;
                        height:100%;
                        
                        &--{
                            &img{
                                width:100%;
                                max-height:25vh;
                                overflow:hidden;
                                background-color: var(--color-grey-21);
                                display: flex;
                                align-items: center;

                                @include respond-above(lg){
                                    max-height: 30vh
                                }
                                @include respond-below(sm){
                                    max-height: 30vh;
                                }

                                img{
                                    width:100%;
                                    min-height: 100%;
                                    // height:25vh;
                                    object-fit: cover;
                                    object-position: top;

                                    @include respond-above(lg){
                                        // height:40vh
                                    }
                                    @include respond-below(sm){
                                        // height:40vh;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &thumb{
                &-{
                    &swiper{
                        width:100% !important;
                        // height:10vh;
                        margin-top:var(--size-4);
                    }
                    &slider{
                        width:100px !important;
                        height:80px !important;
                        opacity: 0.5;

                        .swiper-slide-thumb-active, &.img-active {
                            opacity: 1;
                            border:2px solid var(--color-blue-2);
                            z-index:10;
                        }

                        &--{
                            &img{
                                width:96px;
                                height:76px;
                                overflow:hidden;
                                background-color: var(--color-grey-21);

                                img{
                                    width:96px;
                                    height:76px;
                                    background-color: var(--color-grey-21);
                                    object-fit: cover;
                                    object-position: top;
                                    margin:0 auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}