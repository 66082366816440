#{$ap}-{
    &pro {
        &__{
            &main {
                @media (max-width: 992px) {
                    margin-bottom: 70px;
                }
            }
            &container {
                
                @media (min-width: 768px) {
                    padding: 100px 0;
                    // height: calc(100vh - 80px);
                }
            }
            &row {
                // width: 1074px;
                background: linear-gradient(#0099fe 0%, #0274bd 100%);
                padding: 30px 0px;
                position: relative;
                overflow: hidden;

                @media (min-width: 992px) {
                    padding: 50px 35px;
                }
                @media (min-width: 768px) {
                    border-radius: 25px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    width: 440px;
                    height: 440px;
                    border-radius: 100%;
                    background-color: #41B3FF;
                    top: -310px;
                    left: -180px;
                }
            }
            &title {
                color: #ffffff;

                &- {
                    &text {
                        font-size: 38px;
                        font-weight: 500;

                        @media (min-width: 992px) {
                            font-size: 58px;
                        }
                    }
                }
            }
            &batch {
                font-size: 16px;
                padding: 8px 20px;
                background: transparent linear-gradient(105deg, #F4B94B 0%, #FE8F06 100%) 0% 0% no-repeat padding-box;
                border-radius: 5px;
                box-shadow: 2px 3px 3px #00000040;
                letter-spacing: 2px;

                @media (min-width: 992px) {
                    font-size: 21px;
                    padding: 12px 25px;

                    &- {
                        &icon {
                            font-size: 26px;
                        }
                    }
                }

                &- {
                    &icon {
                        font-size: 18px;
                    }
                }
            }
            &subtitle {
                font-size: 18px;
                color: #ffffff;
                font-weight: 500;
            }
        }
    }
}