@import './variables', './mixins',  './reset', './icons', './typo', './helpers';

// Lato & Roboto font
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');


:root {
    --app-color: #7FD67A;
    --body-font: 'Lato', sans-serif;
    --roboto: 'Roboto', sans-serif;


    //- FONT SIZES

    --text-xxs: 10px;
    --text-xs: 12px;
    --text-sm: 14px;
    --text-md: 16px;
    --text-lg: 18px;
    --text-xl: 20px;
    --text-2xl: 22px;
    --text-3xl: 24px;
    --text-4xl: 26px;
    --text-5xl: 28px;
    --text-6xl: 30px;
    --text-7xl: 32px;
    --text-8xl: 34px;
    --text-9xl: 36px;
    --text-10xl: 38px;
    --text-11xl: 40px;
    --text-abnormal: 128px;


    //- ICON SIZES

    --icon-xs: 5px;
    --icon-sm: 10px;
    --icon-md: 15px;
    --icon-lg: 20px;
    --icon-xl: 25px;
    --icon-2xl: 30px;
    --icon-3xl: 35px;
    --icon-4xl: 40px;
    --icon-5xl: 45px;
    --icon-6xl: 50px;
    --icon-7xl: 55px;
    --icon-8xl: 60px;


    //- FONT WEIGHTS

    --font-thin: 100;
    --font-light: 300;
    --font-normal: 400;
    --font-medium: 500;
    --font-semibold: 600;
    --font-bold: 700;
    --font-extrabold: 800;
    --font-black: 900;


    //- SIZES

    --size-1: 5px;
    --size-2: 10px;
    --size-3: 15px;
    --size-4: 20px;
    --size-5: 25px;
    --size-6: 30px;
    --size-7: 35px;
    --size-8: 40px;
    --size-9: 45px;
    --size-10: 50px;
    --size-11: 55px;
    --size-12: 60px;
    --size-13: 65px;
    --size-14: 70px;
    --size-15: 75px;
    --size-16: 80px;

    

    --rounded: 0.25rem;
    --rounded-full: 9999px;


    //- COLORS

    --color-primary: #3A4EE8;
    --color-blue-2:#3497FD;
    --color-secondary: #0EB964;
    --color-white: #ffffff;
    --color-white-1: #fafafa;
    --color-black: #111111;
    --color-black-2:#252525;
    --color-black-3:#343434;
    --color-black-4:#363636;
    --color-black-5:#292929;
    --color-black-6:#555555;
    --color-black-7:#0A0A0A;
    --color-black-8:#232222;
    --color-section-bg:#F7F7F7;
    --color-grey: #2C2C2C;
    --color-grey-2: #5C5C5C;
    --color-grey-3:#8A8A8A;
    --color-grey-4:#706E6E;
    --color-grey-5:#707070;
    --color-grey-6:#242424;
    --color-grey-7:#646464;
    --color-grey-8:#8D8D8D;
    --color-grey-9:#EAEDFF;
    --color-grey-10:#9B9B9B;
    --color-grey-11: #F4F4F4;
    --color-grey-12:#B3B3B3;
    --color-grey-13:#969696;
    --color-grey-14:#E7E7E7;
    --color-grey-15:#373737;
    --color-grey-16:#EBEBEB;
    --color-grey-17:#949494;
    --color-grey-18:#C0C0C0;
    --color-grey-19:#A1A1A1;
    --color-grey-20:#E0E0E0;
    --color-grey-21:#F5F5F5;
    --color-grey-22:#AFAFAF;
    --color-grey-23:#757575;
    --color-grey-24:#484848;
    --color-grey-25:#585858;
    --color-grey-26:#717171;
    --color-grey-27:#3f3f3f;
    --color-grey-28:#1A1A1A;
    --color-grey-29:#4A4A4A;
    --color-grey-30:#C4C4C4;
    --color-grey-31:#E2E2E2;
    --color-grey-32:#2A2A2A;
    --color-grey-33:#ECECEC;
    --color-grey-34:#F6F6F6;

    --color-cancel: #E3E3E3;


    --color-card-border: #EEEEEE;
    
    --color-lightblue:#1875FB;
    --color-darkblue:#03035D;
    --color-yellow:#EFC729;
    --color-lightgreen:#C1F2DA;
    --color-lightgreen-2:#E9FFF4;
    --color-danger:#F98181;
    --color-pink:#FFEAEA;
    --color-red:#ef4444;
    --color-lightred:#f7c2c2;
    --color-lightred-2:#ffebeb;
    --color-footer:#111317;


    //- Z-INDEX

    --header-z: 100;
    --z-1: 1;
    --z-2: 2;
    --z-4: 4;
    --z-6: 6;
    --z-8: 8;
    --z-10: 10;
    --z-20: 20;
    --z-30: 30;
    --z-40: 40;
    --z-50: 50;
    --z-60: 60;


    //- SECTION SPACING

    --section-spacing: 80px;
}

.section-spacing{
    padding:var(--section-spacing);
}

#{$ap}- {
    &icon {
        display: inline-block;
        &-- {
            &xs {
                width: var(--icon-xs) !important;
                height: var(--icon-xs) !important;
                font-size: var(--icon-xs) !important;
            }
            &sm {
                width: var(--icon-sm) !important;
                height: var(--icon-sm) !important;
                font-size: var(--icon-sm) !important;
            }
            &md {
                width: var(--icon-md) !important;
                height: var(--icon-md) !important;
                font-size: var(--icon-md) !important;
            }
            &lg {
                width: var(--icon-lg) !important;
                height: var(--icon-lg) !important;
                font-size: var(--icon-lg) !important;
            }
            &xl {
                width: var(--icon-xl) !important;
                height: var(--icon-xl) !important;
                font-size: var(--icon-xl) !important;
            }
            &2xl {
                width: var(--icon-2xl) !important;
                height: var(--icon-2xl) !important;
                font-size: var(--icon-2xl) !important;
            }
            &3xl {
                width: var(--icon-3xl) !important;
                height: var(--icon-3xl) !important;
                font-size: var(--icon-3xl) !important;
            }
            &4xl {
                width: var(--icon-4xl) !important;
                height: var(--icon-4xl) !important;
                font-size: var(--icon-4xl) !important;
            }
            &5xl {
                width: var(--icon-5xl) !important;
                height: var(--icon-5xl) !important;
                font-size: var(--icon-5xl) !important;
            }
            &6xl {
                width: var(--icon-6xl) !important;
                height: var(--icon-6xl) !important;
                font-size: var(--icon-6xl) !important;
            }
            &7xl {
                width: var(--icon-7xl) !important;
                height: var(--icon-7xl) !important;
                font-size: var(--icon-7xl) !important;
            }
            &8xl {
                width: var(--icon-8xl) !important;
                height: var(--icon-8xl) !important;
                font-size: var(--icon-8xl) !important;
            }
        }
    }
    // Icon: End
}

.body-font {
    font-family: var(--body-font) !important;
}

// @include Spacings;

@include TextSizes;