@import './fonts',
        './button',
        './input',
        './card',
        './project-tabs',
        './pagination',
        './project-inconversation',
        './project-sliders',
        './modal',
        './payment',
        './dropdown',
        './date-picker',
        './page-error',
        './popup-notification';

    
#{$ap}- {
    &data{
        &__{
            &loader{
                width:100%;
                height:calc(100vh - var(--size-10));
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}