#{$ap}- {
    &greviews {
        &__ {
            &col {
                margin: auto;
            }
            &bottom {
                display: flex;
                margin-top: 2em;
            }
            &info {
                color: #222222;
                font-size: var(--text-lg);

                &-- {
                    &bold {
                        font-weight: 700;
                    }
                }
            }
            &step {
                display: flex;
                margin-top: 25px;
                &- {
                    &left {
                        width: 65px;
                        height: 65px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        font-size: var(--text-10);
                        border: 1px solid #707070;
                        border-radius: 100px;
                    }
                    &right {
                        width: calc(100% - 65px);
                        padding-left: 15px;
                        padding-top: 10px;
                    }
                    &number {
                        font-size: var(--text-12);
                        font-weight: 700;
                    }
                    &title {
                        font-size: var(--text-md);
                        font-weight: 700;
                    }
                    &para {
                        font-size: var(--text-md);
                    }
                }
            }
            &tick-wrap {
                width: 20px;
                height: 20px;
                color: #0db963;
                border: 1px solid;
                background: #ffffff;
                border-radius: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &conformation {
                &- {
                    &text {
                        width: calc(100% - 20px);
                        font-size: var(--text-md);
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}
