#{$ap}- {
    &notification {
        &__ {
            &inwrap {
            }
            &row {
                &- {
                    &wrap {
                    }
                }
            }
            &new {
                // border-bottom: 1px solid var(--color-grey-13);
                &- {
                    &date {
                        font-size: var(--text-md);
                        font-weight: var(--font-normal);
                        color: var(--color-grey-12);
                    }
                }
            }
            &empty {
                &- {
                    &title {
                        font-size: var(--text-3xl);
                        font-weight: var(--font-medium);
                    }
                    &subtitle {
                        font-size: var(--text-lg);
                        color: var(--color-grey-15);
                    }
                }
            }
            &project {
                &- {
                    &left {
                        width: calc(100% - 110px);
                    }
                    &right {
                        width: 110px
                    }
                    &image {
                        // border-right: 1px solid var(--color-grey-13);
                        padding-right: var(--size-4);
                    }
                    &img {
                        width: 20px;
                        height: auto;
                    }
                    &content {
                        @media (min-width: 768px) {                            
                            padding-left: 50px;
                            padding-right: 50px;
                            // border-left: 1px solid #E7E7E7;
                            // height: 65px;
                        }
                    }
                    &heading {
                        font-size: var(--text-md);
                        font-weight: var(--font-medium);
                        color: var(--color-primary);
                        margin-bottom: 8px;
                    }
                    &title {
                        font-size: var(--text-sm);
                        font-weight: var(--font-medium);
                        color: var(--color-black);
                    }
                    &link {
                        font-size: var(--text-xs);
                        font-weight: var(--font-medium);
                        color: var(--color-primary);
                    }
                    &time {
                        font-size: var(--text-xxs);
                        font-weight: var(--font-medium);
                        color: var(--color-grey-12);
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 1200px) {
    #{$ap}- {
        &notification {
            &__ {
                &row {
                    &- {
                        &wrap {
                            padding-left: 280px;
                            padding-right: 280px;
                        }
                    }
                }
            }
        }
    }
}
