#{$ap}- {
    &pagination {
        display: inline-flex;
        padding: 10px;
        background-color: #F1F3FF;

        &__ {
            &item {
                & a {
                    width: 30px;
                    height: 30px;
                    border-radius: 5px;

                    @include respond-above(md){
                        width: 40px;
                        height: 40px;
                    }
                }

                &.disabled {
                    opacity: 0.2;
                }
                &- {
                    &active {
                        & a {
                            background-color: var(--color-primary);
                            color: var(--color-white);
                        }
                    }
                }
            }
        }
    }
}