#{$ap}- {
  &header {
    position: fixed;
    width: 100vw;
    height: 80px;
    top: 0;
    left: 0;
    background: var(--white);
    z-index: var(--header-z);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 10px 6px;

    &__ {
      &inner {
        &-- {
          &prelogin {
            height: 80px;
            @include respond-above(lg) {
              padding: 0 var(--size-12);
            }
          }
          &postlogin {
            height: 80px;
            padding-left: var(--size-4);
            padding-right: 0;

            @include respond-above(lg) {
              padding-left: var(--size-12);
            }
          }
        }
      }

      &brand {
        margin-right: var(--size-4);
        // width:100px;
        height: 65px;

        @include respond-above(lg) {
          margin-right: var(--size-12);
        }
      }

      &menu {
        box-shadow: 0px 3px 20px #e7e7e75c;
        border: 1px solid #eeeeee;
        &- {
          //- Mobile Version: Start
          &toggler {
            img {
              width: var(--size-6);
              height: var(--size-6);
            }
          }
          &collapse {
            position: absolute;
            top: 0;
            right: 0;
            left: unset;
            width: 300px;
            min-height: 100vh;
            background-color: var(--color-white);
            padding: var(--size-2) var(--size-5);
            box-shadow: -1px 0px 3px 0px #c0c0c029;

            &.collapsing {
              height: 100vh !important;
              transition: all 0.25s ease-in-out !important;
              -webkit-transition: all 0.25s ease-in-out !important;
              -moz-transition: all 0.25s ease-in-out !important;
              display: block !important;
              right: 0 !important;
            }
            &:not(.show) {
              z-index: 1000;
              transform: translateX(280px);
              -webkit-transform: translateX(280px);
              -moz-transform: translateX(280px);
            }
            &.show {
              z-index: 1000;
              right: 0%;
              animation: slideInRight 0.35s ease;
              -webkit-animation: slideInRight 0.3s ease;
              -moz-animation: slideInRight 0.3s ease;
              animation-delay: 0 !important;
              transition: all 0.25s ease-in-out !important;
              -moz-transition: all 0.25s ease-in-out !important;
              -webkit-transition: all 0.25s ease-in-out !important;

              @keyframes slideInRight {
                from {
                  opacity: 0;
                  transform: translateX(280px);
                  transition: all 0.55s ease-in-out;
                }
                to {
                  opacity: 1;
                  transform: translateX(0);
                  transition: all 0.55s ease-in-out;
                }
              }
            }
          }
          &close {
            img {
              width: var(--size-5);
              height: var(--size-5);
            }
          }
          //- Bottom NavLinks
          &btm {
            width: 100%;
            bottom: 0;
            left: 0;
            background-color: var(--color-white);
            box-shadow: 0px 3px 30px #c0c0c029;
            border-top: 1px solid var(--color-grey-16);
            padding: var(--size-3) var(--size-2) var(--size-2);
            z-index: 100;

            @include respond-above(sm) {
              padding: var(--size-4) var(--size-8);
            }
          }
          &tab {
            padding: 0;
            color: var(--color-grey-8);

            &.active {
              border-top: none;
              color: var(--color-secondary) !important;
            }

            &-- {
              &img {
                img {
                  width: var(--size-4);
                  height: var(--size-4);
                }
              }
            }
          }
          //- Mobile Version: End

          &item {
            height: 100%;
            padding: 0;
            display: inline-block;

            @include respond-above(md) {
              margin-left: var(--size-2);
            }

            @include respond-above(xl) {
              margin-left: var(--size-6);
            }
          }
          &link {
            height: 100%;
            position: relative;
            font-size: var(--text-md);
            font-weight: var(--font-medium);
            color: var(--color-grey-25);
            padding: calc(var(--size-2) + 6px) 0;

            @include respond-above(xl) {
              font-size: var(--text-md);
            }

            @include respond-below(md) {
              font-weight: var(--font-medium);
              padding: var(--size-2) 0;
            }

            &.active {
              // border-top:4px solid var(--color-primary);
              @include respond-above(lg) {
                &::before {
                  content: "";
                  width: 100%;
                  height: 8px;
                  background-color: var(--color-primary);
                  position: absolute;
                  top: -1px;
                }
              }

              @include respond-below(md) {
                border-top: none;
                color: var(--color-secondary);
              }
            }

            img {
              width: var(--size-4);
              height: var(--size-4);

              @include respond-below(md) {
                width: var(--size-3);
                height: var(--size-3);
              }
            }
          }

          &projects {
            position: relative;
            margin-left: var(--size-4);
            padding-left: var(--size-3);

            &:before {
              position: absolute;
              content: "";
              width: 1.5px;
              height: 50%;
              left: 0;
              top: 22%;
              background-color: var(--color-grey-9);
            }

            @include respond-above(lg) {
              margin-left: var(--size-10);
              padding-left: var(--size-5);
            }
          }
        }
      }

      //- Notification Dropdown: Start
      &notification {
        &- {
          &icon {
            font-size: var(--text-8xl) !important;

            @include respond-above(md) {
              font-size: var(--text-3xl) !important;
            }

            &-- {
              &badge {
                background-color: var(--color-white);
                color: var(--color-primary);
                font-size: var(--text-sm);
                right: -12px;
                top: 0;
                position: absolute;
                padding: 2px 4px;

                @include respond-below(md) {
                  font-size: var(--text-lg);
                  right: -2px;
                }
              }
            }
          }
          &dropdown {
            font-weight: var(--font-normal);
          }
          &menu {
            box-shadow: 0px 3px 20px #e7e7e75c;
            border: 1px solid #eeeeee;

            &.show {
              width: 350px !important;
              padding: var(--size-5) var(--size-4);
              margin-top: var(--size-3);
              // transition:all 0.25s linear;-webkit-transition:all 0.25s linear;-moz-transition:all 0.25s linear;

              @include respond-above(md) {
                margin-top: calc(var(--size-5) - 3px);
              }
              @include respond-below(sm) {
                width: 100vw !important;
                left: -5px !important;
                border: none !important;
              }
            }
          }
          &top {
            border-bottom: 2px solid var(--color-grey-16);
            padding-bottom: var(--size-3);
          }
          &title {
            font-size: var(--text-md);
            letter-spacing: 0.26px;
            font-weight: var(--font-bold);
            color: var(--color-grey-2);
          }
          &link {
            color: var(--color-secondary);
            font-size: var(--text-sm);
          }
          &list {
            height: 320px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 0;
              background: transparent;
            }
            &::-webkit-scrollbar-thumb {
              background: transparent;
            }
          }
          &item {
            padding: var(--size-4) 0;
            border-bottom: 1px solid var(--color-grey-16);
            cursor: default;

            &:last-child {
              border-bottom: 0px !important;
            }

            &-- {
              &img {
                width: 50px;
              }
              &info {
                width: calc(100% - 50px);
                border-left: 1px solid var(--color-grey-16);
                padding: 0 var(--size-4) 0;
              }
              &title {
                font-size: var(--text-sm);
                font-weight: var(--font-semibold);
                color: var(--color-grey-6);
                line-height: 1.3;
              }
              &link {
                color: var(--color-primary);
                font-size: var(--text-xs);
                font-weight: var(--font-medium);
                margin-bottom: var(--size-2);

                &:hover {
                  color: var(--color-secondary);
                }
              }
              &timestamp {
                font-size: var(--text-xs);
                color: var(--color-grey-13);
              }
            }
          }
        }
      }
      //- Notification Dropdown: End

      &profile {
        background-color: #fafafa;
        padding: 0 !important;

        &- {
          &img {
            width: var(--size-9);
            height: var(--size-9);
            border-radius: var(--rounded-full);
            background-color: var(--color-grey-9);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            img {
              width: var(--size-9);
              height: var(--size-9);
              border-radius: var(--rounded-full);
              object-fit: cover;
              object-position: top;
            }

            &-- {
              &mobile {
                width: var(--size-7);
                height: var(--size-7);

                img {
                  width: var(--size-7);
                  height: var(--size-7);
                }
              }
            }
          }
          &info {
            line-height: 1.2;
          }
          &name {
            color: var(--black);
            font-size: var(--text-sm);
            font-weight: var(--font-semibold);
            text-transform: capitalize;

            @include respond-below(md) {
              font-size: var(--text-md);
            }
          }
          &text {
            font-size: var(--text-xs);
            font-weight: var(--font-medium);
            color: var(--black);
            opacity: 0.45;
            text-transform: capitalize;
          }
          &pro {
            padding: 4px 8px;
            width: auto !important;
            display: inline-block !important;
            font-size: 10px !important;
          }
        }
      }

      &dropdown {
        &.show {
          & #{$ap}-header__dropdown-icon--arrow {
            transform: rotate(-180deg);
          }
        }

        &:hover {
          & #{$ap}-header__dropdown-menu {
            display: block;
            animation: dropdownShow 0.2s ease 0s forwards;
            transition: all 0.2s ease;
          }
          & #{$ap}-header__dropdown-icon--arrow {
            transform: rotate(-180deg);
          }
        }

        &- {
          &toggle {
            height: 80px;
            padding: var(--size-2) calc(var(--size-2) + 8px);
            // margin-right: var(--size-2);

            &-- {
              &mywork {
                height: 100% !important;
              }
            }
          }
          &menu {
            width: 230px !important;
            margin-top: -0.5px;
            border: 1px solid #eeeeee;
            // // box-shadow: 0px 3px 10px #00000033;
            padding: var(--size-2);
            transform: translateY(-20px);
            left: unset !important;
            right: 30px !important;
            z-index: 50 !important;

            &.show {
              width: 220px !important;
              margin-top: -0.5px;
              border: 1px solid #eeeeee;
              // box-shadow: 0px 3px 10px #00000033;
              padding: var(--size-2);
              left: unset !important;
              right: -2px !important;
              z-index: 50 !important;
              // transition:all 0.2s linear;-webkit-transition:all 0.2s linear;-moz-transition:all 0.2s linear;
            }
          }

          &icon {
            &-- {
              // transition: all 0.2s ease;
              &video {
                width: 0.4em;
              }
            }
          }

          &item {
            font-size: var(--text-sm);
            color: var(--color-grey-25);
            font-weight: var(--font-medium);
            padding: var(--size-2) var(--size-3);
            border-radius: 5px;

            &.active {
              background-color: var(--color-secondary) !important;
              color: var(--color-white);
            }

            &:focus {
              background-color: #f2f2f2 !important;
              // color:var(--color-white);
            }
            &:first-child {
              // padding: var(--size-2) var(--size-3) !important;
              font-size: var(--text-md);
              color: var(--color-black);

              &:hover {
                background: none !important;
              }
            }
            &:last-child {
              margin-top: var(--size-4);
            }

            &:hover {
              background-color: #f5f9fa;
            }

            img {
              width: var(--size-3);
              height: var(--size-3);
            }
          }
        }
      }
    }
  }
}

@keyframes dropdownShow {
  0% {
    display: block;
  }
  100% {
    transform: translateY(0);
  }
}
