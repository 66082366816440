#{$ap}- {
    &dropdown {
        &__ {
            &menu {
                margin: 0;
            }
            &item {
                font-size: var(--text-sm);
                color: var(--color-grey-25);
                font-weight: var(--font-medium);
                padding: var(--size-2) var(--size-3);
                border-radius: 5px;

                &.active, &:focus{
                    background-color:var(--color-secondary) !important;
                    color:var(--color-white);
                }
            }
        }
    }
}