#{$ap}- {
    &card {
        font-size: var(--text-sm);
        border: 1px solid var(--color-card-border);
        &__ {
            &body {
                &- {
                    &status {
                        height: calc(100% - 130px);
                    }
                }
            }
            &wrap {
                height: 100%;
                position: relative;
                transition: all 0.2s ease;
                &::before {
                    content: "";
                    width: 80%;
                    margin: 0 auto;
                    position: absolute;
                    height: 100px;
                    left: 0;
                    right: 0;
                    bottom: 10px;
                    border-radius: 10px;
                    box-shadow: 0px 10px 15px rgba(41, 41, 41, 0.36);
                    z-index: 0;
                    transition: all 0.2s ease;
                }

                &-{
                    &inconversation{
                        cursor: pointer;
                        &::before{
                            height:90px !important;
                            border-radius:0 !important;
                            bottom: 0 !important;
                        }
                    }
                }
                
                &-- {
                    &fixed-width {
                        width: 320px;
        
                        @include respond-above(md) {
                            width: 376px;
                        }
                    }
                }

                &-- {
                    &secondary {
                        &::before {
                            width: 95%;
                        }
                    }
                }
                // &:hover {
                //     &::before {
                //         box-shadow: 0px 0px 0px;
                //     }
                // }
            }

            &primary {
                // background-size: cover;
                // background-repeat: no-repeat;
                // background-position: center;
                position: relative;
                border-radius: 10px;
                overflow: hidden;
                transition:all 0.2s ease-in-out;
                -webkit-transition:all 0.2s ease-in-out;
                -moz-transition:all 0.2s ease-in-out;

                &:before {
                    content: "";
                    width: 100%;
                    height: 50%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    z-index: 1;
                    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.16) 28%, rgba(0,0,0,0.57) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.16) 28%,rgba(0,0,0,0.57) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.16) 28%,rgba(0,0,0,0.57) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#91000000',GradientType=0 );
                }

                &:hover{
                    // background-color: var(--color-grey-33);
                    // transition:all 0.2s ease-in-out;-webkit-transition:all 0.2s ease-in-out;-moz-transition:all 0.2s ease-in-out;

                    & #{$ap}-card__primary-image {
                        transform: scale(1.2);
                    }
                }

                &- {
                    &content {
                        position: relative;
                        z-index: 2;
                    }
                    &image {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                        transition:all 0.2s ease-in-out;
                        opacity: 0.8;
                    }
                    &title {
                        font-size: var(--text-xl);
                        line-height: calc(var(--text-xl) + 8px);
                        font-weight: var(--font-normal);
                        color: var(--color-white);
                        border-bottom: 1px solid #000;
                    }
                    &icon {
                        width: calc(var(--size-9) * 2);
                        height: calc(var(--size-9) * 2);
                        border-radius: 100%;
                        background-color: rgba(206, 233, 249, 0.54);
                    }
                }
            }
            &count {
                font-size: var(--text-xl);
                line-height: calc(var(--text-xl) + 8px);
                font-weight: var(--font-semibold);
                &- {
                    &wrap {
                        color: var(--color-grey);
                        padding: var(--size-1) var(--size-3) var(--size-8);
                        // background-color: var(--color-grey-11);
                        border-radius: 5px;
                        overflow: hidden;
                        position: relative;
                        -webkit-backdrop-filter: blur(9px);
                        backdrop-filter: blur(9px);
                        background: rgba(255, 255, 255, 0.8);

                        &::before {
                            content: "";
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            // background-color: #ffffff;
                            border-radius: 5px;
                            z-index: -1;
                            opacity: 0.6;
                        }
                    }
                    &text {
                        font-size: var(--text-xs);
                        line-height: calc(var(--text-xs) + 8px);
                        font-weight: var(--font-normal);
                    }
                }
            }
            &label {
                text-align: center;
                padding: 5px 15px;
                color: var(--color-white);
                background: #3a4ee8;
                font-size: var(--text-xs);
                border-radius: 5px;
                margin-bottom: 5px;
            }


            &text {
                &-- {
                    &white {
                        color: var(--color-white);
                    }
                    &green {
                        color: var(--color-secondary);
                    }
                    &md {
                        font-size: var(--text-md);
                    }
                }
            }

            &bill {
                &- {
                    &wrap {
                        border-bottom: 1px dashed rgba(58, 78, 232,  0.25);
                    }
                }
            }

            // Secondary

            &secondary {
                height: 100%;
                border-radius: 10px;
                overflow: hidden;
                border: 1px solid #EEEEEE;
                transition:all 0.2s ease-in-out;
                -webkit-transition:all 0.2s ease-in-out;
                -moz-transition:all 0.2s ease-in-out;

                &:hover{
                    // background-color: var(--color-grey-33);
                    // transition:all 0.2s ease-in-out;-webkit-transition:all 0.2s ease-in-out;-moz-transition:all 0.2s ease-in-out;

                    & #{$ap}-card__image-inwrap {
                        transform: scale(1.2);
                        transition:all 0.2s ease-in-out;
                    }
                }

                & > a {
                    height: 100%;
                }

                &- {
                    &title {
                        padding-right: 20px;
                        font-size: var(--text-lg);
                        line-height: calc(var(--text-lg) + 8px);
                        font-weight: var(--font-normal);
                        color: var(--color-primary);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &s {
                            width: calc(100% - 130px);
                        }
                    }
                    &body {
                        height: calc(100% - 130px);
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                }
            }
            &image {
                border-radius: 0;

                &- {
                    &wrap {
                        height: 130px;
                        overflow: hidden;
                    }
                    &inwrap {
                        height: 100%;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                        transition:all 0.2s ease-in-out;
                    }
                }
            }
            &subtitle {
                font-size: var(--text-md);
                line-height: calc(var(--text-md) + 8px);
            }
            &pending{
                background-color: var(--color-pink);
                border-radius: var(--rounded);
                &-{
                    &title{
                        font-size: var(--text-sm);
                        font-weight: var(--font-medium);
                        color: var(--color-black-8);
                    }
                    &subtitle{
                        font-size: var(--text-xs);
                        font-weight: var(--font-light);
                        color: var(--color-black);
                    }
                }
            }
            &phone{
                &-{
                    &icon{
                        background: #e0efff;
                        border-radius: 100%;
                        color:var(--color-blue-2);
                    }
                    &label {
                        font-size: var(--text-md);
                    }
                }
            }
            &status {
                &- {
                    &wrap {
                        padding: 10px 20px;
                        // border: 1px solid #E6E6E6;
                        background-color: #F5F5F5;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 8px;
                    }
                    &label {
                        font-size: var(--text-sm);
                        font-weight: var(--font-semibold);
                    }
                    &light {
                        width: 18px;
                        height: 18px;
                        border-radius: 100px;

                        &-- {
                            &cancel {
                                background: #F63F3F;
                            }
                            &cancel-request {
                               background-color: #e7e131; 
                            }
                            &ongoing {
                                background: #07D12F;
                            }
                        }
                    }
                }
            }
            &location {
                font-family: var(--roboto);
            }

            // Tertiary
            &tertiary {
                padding: var(--size-2) var(--size-3);
                margin-bottom: var(--size-4);

                &:last-child {
                    margin-bottom: 0;
                }

                @include respond-above(md){
                    padding-right: 70% !important;
                } 
                &- {
                    &icon {
                        width: 20px;
                        height: 20px;
                        background-color: rgba(206, 233, 249, 0.54);
                        border-radius: 100%;
                    }
                    &text {
                        width: calc(100% - 35px);
                    }
                }
            }

            // Quaternary
            &quaternary {
                margin-bottom: var(--size-3);
                position: relative;
                border: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    content: "";
                    width: 90%;
                    margin: 0 auto;
                    position: absolute;
                    height: 10px;
                    left: 0;
                    right: 0;
                    bottom: 10px;
                    border-radius: 10px;
                    box-shadow: 0px 10px 15px rgba(41, 41, 41, 0.36);
                    z-index: -1;
                    transition: all 0.2s ease;
                }
                &- {
                    &icon {
                        width: 42px;
                        height: 42px;
                        border-radius: 6px;
                        background-color: var(--color-grey-8);
                        overflow: hidden;
                    }
                    &title {
                        width: 100%;
                        font-size: var(--text-lg);
                        line-height: calc(var(--text-lg) + 8px);
                        color: var(--color-primary);

                        @include respond-below(sm){
                            font-size: var(--text-md);
                            font-weight: var(--font-medium);
                        }
                    }
                    &content {
                        width: calc(100% - 57px);
                    }
                    &text {
                        color: var(--color-grey-8);
                    }
                    &date {
                        width: 150px;
                        color: var(--color-grey-8);

                        @include respond-below(sm){
                            margin-top:var(--size-2);
                        }
                    }
                }
            }

            // Fancy
            &fancy {
                margin-bottom: 30px;
                position: relative;

                &- {
                    &wrap {
                        z-index: 2;
                    }
                    &inwrap {
                        background-color: var(--color-white);
                        padding: 30px;
                        border-radius: 10px;
                        position: relative;
                        overflow: hidden;
                        box-shadow: 0 5px 10px #f1f1f1;

                        &::before, &::after {
                            content: "";
                            width: 460px;
                            height: 460px;
                            background-color: #41B3FF;
                            border-radius: 100%;
                            position: absolute;
                        }

                        &::before {
                            transform: translate(80px, -80px);
                            opacity: 0.06;
                            z-index: 0;
                        }
                        &::after {
                            transform: translate(-220px, -510px);
                            opacity: 0.16;
                            z-index: 1;
                        }

                        &-- {
                            &image {
                                background-size: cover;
                                background-repeat: no-repeat;
                                &::before, &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                    &profile {
                        width: 50px;
                        height: 50px;
                        overflow: hidden;
                        border-radius: 100px;

                        & img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    &title, &rating {
                        font-size: 20px;
                        line-height: 26px;
                        font-weight: var(--font-medium);
                    }
                    &details {
                        width: calc(100% - 50px);
                        padding-left: 25px;
                    }
                    &content {
                        margin-top: 25px;

                        max-width: 100%;
                        // height: 74px;
                        // display: -webkit-box;
                        // -webkit-line-clamp: 3;
                        // -webkit-box-orient: vertical;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // margin: 0 auto;
                        /* line-height: 1; */
                    }
                    &text {
                        &-- {
                            &label {
                                font-size: 14px;
                                color: grey;
                            }
                            &grey {
                                color: rgba(0,0,0,0.62);
                            }
                        }
                    }
                }

                &:nth-child(2n) {
                    & #{$ap}-card__fancy-inwrap {
                        &::after {
                            transform: translate(-60px, -30px);
                        }
                    }
                }
                &:nth-child(4n) {
                    & #{$ap}-card__fancy-inwrap {
                        &::after {
                            transform: translate(-330px, -500px);
                        }
                    }
                }
            }

            //Home Notification
            &others {
                background-color: #ffffff;
                border-radius: 10px;
                position: relative;
                padding: var(--size-6) var(--size-4);

                &::before {
                    content: "";
                    width: 90%;
                    margin: 0 auto;
                    position: absolute;
                    height: 50%;
                    left: 0;
                    right: 0;
                    bottom: 10px;
                    border-radius: 10px;
                    box-shadow: 0px 10px 15px rgba(41, 41, 41, 0.36);
                    z-index: -1;
                    transition: all 0.2s ease;
                }

                &-- {
                    &shadow {
                        box-shadow: 0px 3px 10px rgba(207, 207, 207, 0.36);
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}