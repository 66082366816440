#{$ap}- {
    &popup {
        &__ {
            &notification {
                
                &- {
                    &icon {
                        width: 30px;
                    }
                    &content {
                        width: calc(100% - 30px);
                        padding-left: 10px;
                    }
                    &title {
                        font-size: 16px;
                        font-weight: 500;
                        color: #292929;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

.Toastify__toast-icon {
    display: none !important;
}
.Toastify__close-button--light {
    opacity: 1 !important;
}
.Toastify__close-button > svg {
    fill: #3A4EE8;
}
.Toastify__progress-bar {
    height: 3px;
}
.Toastify__progress-bar--info {
    background: #3A4EE8 !important;
}