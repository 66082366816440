/* CustomerDetails.css */

/* General card styles */
.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: #fff;
    /* margin-bottom: 16px; */
    position: relative;
  }
  
  main.ebw-main{
    min-height: calc(100vh - 170px);
  }
  .ebw {
    min-height: 100vh;
}
  /* .card img {
    width: 100%;
    height: 160px;
    object-fit: cover;
  } */
  
  .menu {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }
  
  .card-content {
    padding: 16px;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 10px;
    color: #333;
  }
  
  .card-subtitle {
    font-size: 14px;
    color: #666;
    margin-bottom: 16px;
  }
  
  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-top: 1px solid #ddd;
  }
  
  .location,
  .icon {
    display: flex;
    align-items: center;
    color: #888;
  }
  
  .location svg,
  .icon svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    /* fill: #888; */
  }
  
  /* Customer Details Styles */
  .es-customer__image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
  }
  
  .es-customer__name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .es-customer__location {
    font-size: 14px;
    color: #666;
  }
  
  .es-card__actions {
    margin-top: 16px;
  }
  
  .es-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .es-button__primary {
    background-color: #3D84FB;
    color: white;
    border: none;
  }
  
  .es-button__bordered {
    background-color: transparent;
    color: #007bff;
    border: 1px solid #3D84FB;
  }
  
  .es-project__info {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  
  .es-project__info--plain {
    font-size: 12px;
    color: #999;
  }
  
  .icon-phone-wave {
    margin-right: 4px;
  }
  
  .icon-location-line {
    margin-right: 8px;
    fill: #007bff;
  }

  /* styles.css */

/* body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  } */
  
  .lead-card {
    background-color: #fff;
    /* width: 320px; */
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }
  
  .lead-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #eee;
  }
  
  .profile {
    display: flex;
    align-items: center;
  }
  
  .profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .profile-name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .lead-price {
    text-align: right;
  }
  
  .price {
    font-size: 18px;
    font-weight: bold;
    color: #28a745;
  }
  
  .price-label {
    font-size: 12px;
    color: #666;
    display: block;
  }
  
  .lead-body {
    padding: 16px;
    font-size: 14px;
    color: #555;
    border-bottom: 1px solid #eee;
  }
  
  .lead-footer {
    text-align: center;
    padding: 16px;
  }
  
  .purchase-button {
    background-color: #3D84FB;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .purchase-button .button-icon {
    font-size: 16px;
    margin-right: 8px;
  }
  
  .purchase-button:hover {
    background-color: #3D84FB;
  }


  /* styles.css */
  .text-card {
    background-color: #fff;
    /* width: 320px; */
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
  }
  
  .section {
    margin-bottom: 16px;
  }
  
  .section-title {
    font-size: 14px;
    font-weight: bold;
    color: #888;
    margin-bottom: 4px;
  }
  
  .section-content {
    font-size: 14px;
    color: #333;
    line-height: 1.5;
  }


/* lead purchase  */
  .card {
    background-color: #ffffff;
    border-radius: 12px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    /* padding: 20px; */
    /* box-sizing: border-box; */
  }

  .card-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
  }

  .profile-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profile-info {
    flex: 1;
  }

  .profile-info h2 {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    padding-bottom: 5px;
    text-transform: capitalize;
  }

  .profile-info a {
    color: #888;
    text-decoration: none;
    font-size: 14px;
  }

  .card-price {
    font-size: 18px;
    color: #28a745;
    font-weight: bold;
    text-align: end;
  }

  .card-status {
    color: #aaa;
    font-size: 14px;
  }

  .card-details {
    margin-bottom: 20px;
  }

  .card-details p {
    margin: 8px 0;
    font-size: 14px;
    color: #545458;
    display: flex;
    align-items: center;
  }

  .card-details p svg {
    margin-right: 10px;
    color: #888;
  }

  .call-button {
    display: block;
    width: 100%;
    padding: 10px 0;
    background-color: #3D84FB;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 10px;
  }

  .call-button:hover {
    background-color: #3D84FB;
  }

  .refund-link {
    text-align: center;
    font-size: 14px;
    color: #545458;
  }

  .refund-link a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }

  .refund-link a:hover {
    text-decoration: underline;
  }




/* refund button  */
  .card-refund {
    padding: 20px;
    background: white;
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .card-header-refund {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .profile-info-refund {
    display: flex;
    align-items: center;
  }

  .profile-pic-refund {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
  }

  .profile-pic-refund img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profile-text-refund {
    display: flex;
    flex-direction: column;
  }

  .profile-text-refund h2 {
    font-size: 16px;
    margin: 0;
    font-weight: bold;
  }

  .profile-text-refund a {
    font-size: 14px;
    color: #888;
    text-decoration: none;
  }

  .status-badge-refund {
    background-color: #fff3cd;
    color: #856404;
    padding: 5px 5px;
    font-size: 10px;
    border-radius: 8px;
    font-weight: bold;
    border: 1px solid #ffeeba;
  }

  .card-description-refund{
    font-size: 14px;
    color: #555;
  }

  .call-button-refund {
    display: block;
    width: 100%;
    padding: 10px 0;
    background-color: #007bff;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    margin-top: 10px;
  }

  .call-button-refund:hover {
    background-color: #0056b3;
  }





/* alert button  */
  .card-popup {
    padding: 20px;
    text-align: center;
  }

  .icon-container-popup {
    width: 50px;
    height: 50px;
    margin: 0 auto 15px auto;
    background-color: #eaf7ea;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-container-popup svg {
    width: 51px;
    height: 51px;
  }

  .card-title-popup {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }

  .card-description-popup {
    font-size: 16px;
    color: #555;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .button-popup {
    display: block;
    width: 100%;
    padding: 10px 0;
    background-color: #3D84FB;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .button-popup:hover {
    background-color: #3D84FB;
  }





/* alert wallet card  */
  .purchase-card {
    padding: 24px;
}

.purchase-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}

.purchase-description {
    color: #B2B2B2;
    font-size: 14px;
    margin-bottom: 24px;
}

.price-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px #e6e6e6 solid;
  flex-direction: row;
  flex-wrap: wrap;
}

.price-label {
    color: #333;
    font-size: 16px;
}

.price-value {
    font-weight: 700;
    font-size: 16px;
}

.confirm-button {
    background-color: #3D84FB;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 14px;
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.confirm-button:hover {
    background-color: #3D84FB;
}



.title-refund {
  font-size: 14px;
  font-weight: bold;
  color: #545458;
  margin-bottom: 20px;
}

.form-group-refund {
  margin-bottom: 15px;
}

.label-refund {
  font-size: 12px;
  font-weight: 600;
  color: #555;
  margin-bottom: 5px;
  display: block;
}

.select-refund,
.textarea-refund {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
}

.select-refund {
  height: 40px;
  background: #fff;
}

.textarea-refund {
  resize: none;
  height: 80px;
}

.button-refund {
  width: 100%;
  padding: 10px;
  background-color: #3D84FB;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  margin-top: 10px;
}

.button-refund:hover {
  background-color: #3D84FB;
}

.textarea-container {
  position: relative;
  /* width: 320px; */
}

.textarea-label {
  position: absolute;
  top: -10px;
  left: 20px;
  background: #f9f9f9;
  padding: 0 5px;
  font-size: 12px;
  color: #888;
  font-weight: 600;
}

.styled-textarea {
  width: 100%;
  height: 100px;
  padding: 15px 20px 10px 20px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  resize: none;
}

.styled-textarea:focus {
  border-color: #007bff;
}


.bid-details-card {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  background: #ffffff;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

.bid-details-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

.bid-title {
  font-size: 18px;
  font-weight: bold;
  color: #444;
}

.bid-date {
  font-size: 14px;
  color: #888;
}

.bid-subtitle {
  font-size: 16px;
  font-weight: 600;
  color: #555;
  margin-top: 10px;
}

.bid-description {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}

.btn {
  border-radius: 8px;
  font-size: 16px;
}

.btn i {
  font-size: 18px;
}