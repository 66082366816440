#{$ap}- {
    &button {
        width: 100% !important;
        display: block;
        border: none;
        padding: var(--size-3) var(--size-4);
        border-radius: 4px;
        user-select: none;
        -webkit-user-select: none;

        &__ {
            &primary {
                color: var(--color-white);
                background-color: var(--color-secondary);
                border-radius: 4px;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                cursor: pointer;

                &.border-none {
                    border: none !important;
                }

                &:hover {
                    color: var(--color-white);
                    border: none;
                    background-color: $active-hover-color;
                }

                & a {
                    color: var(--color-white) !important;
                }

                &-- {
                    &radius {
                        border-radius: 100px;
                    }
                    &no-border {
                        border: none;
                    }
                    &danger {
                        background: var(--color-red) !important;
                    }
                    &grey {
                        color: #6f6f6f !important;
                        background: var(--color-cancel) !important;
                    }
                }
            }
            &secondary {
                color: var(--color-white);
                background-color: var(--color-blue-2);
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

                &:hover,
                &:focus,
                &:active {
                    color: var(--color-white);
                    border: none;
                    background-color: var(--color-lightblue) !important;
                }
            }
            &tertiary {
                color: var(--color-black);
                border: 1px solid var(--color-secondary);
            }
            &yellow {
                color: var(--color-white);
                background-color: var(--color-yellow);
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                text-transform: uppercase;
                font-weight: var(--font-semibold);
                letter-spacing: 0.65px;

                &:hover {
                    border: none;
                    background-color: var(--color-yellow);
                }
            }
            &plain {
                padding: 0;
                cursor: pointer;
                &-- {
                    &blue {
                        // font-size: var(--text-xs);
                        color: var(--color-primary);

                        &:hover {
                            color: var(--color-primary);
                        }
                    }
                    &chat {
                        color: var(--color-primary);

                        &:hover {
                            color: var(--color-primary);
                        }
                    }
                    &green {
                        color: var(--color-secondary);
                        &:hover {
                            color: var(--color-secondary);
                        }
                    }
                    &white {
                        background-color: var(--color-white);
                        color: var(--color-black);
                        padding: calc(var(--size-1) + 3px) var(--size-2);
                        border: 1px solid var(--color-grey-18);

                        &:hover,
                        &:focus {
                            background-color: transparent !important;
                            color: var(--color-black) !important;
                            border: 1px solid var(--color-blue-2) !important;
                            outline: 0;

                            & > .ebw-icon__arrow {
                                color: var(--color-blue-2);
                                font-weight: var(--font-bold);
                            }
                        }
                    }
                    &grey {
                        color: var(--color-grey-13);
                    }
                }

                &- {
                    &text {
                        transform: translateX(20px);
                        transition: all 0.3s ease;
                    }
                    &icon {
                        opacity: 0;
                        transition: all 0.3s ease;
                        padding-top: 2px;
                    }
                }

                &:hover {
                    & #{$ap}-button__plain- {
                        &text {
                            transform: translateX(-5px);
                        }
                        &icon {
                            opacity: 1;
                        }
                    }
                }
            }
            &border {
                background: none;
                border: 1px solid;
                font-size: var(--text-md);
                line-height: calc(var(--text-md) + 10px);
                color: var(--color-grey-30);
                cursor: pointer;
                transition: all 0.2s ease;

                &-- {
                    &blue {
                        border-color: var(--color-primary);
                        color: #38383c;

                        &:hover {
                            color: var(--color-white);
                            background-color: var(--color-primary);
                        }
                    }
                    &chat {
                        color: var(--color-white);
                        background-color: var(--color-primary);

                        &:hover {
                            color: #38383c;
                            border-color: var(--color-primary);
                            background: none;
                        }
                    }
                    &darkgrey {
                        color: #38383c;
                        &:hover {
                            color: var(--color-white);
                            background-color: #38383c;
                        }
                    }
                }
            }
            &arrow {
            }

            &dropdown {
                &-- {
                    &blue {
                        .btn-secondary {
                            background: var(--color-primary);
                            color: var(--color-white);
                            border: none !important;
                        }
                        .dropdown-toggle {
                            background: var(--color-primary) !important;
                            color: var(--color-white) !important;
                            border-radius: 7px;
                        }
                        &.show > .dropdown-toggle:after {
                            transform: rotate(180deg);
                            -webkit-transform: rotate(180deg);
                            -moz-transform: rotate(180deg);
                            transition: all 0.25s ease-in-out;
                            -webkit-transition: all 0.25s ease-in-out;
                            -moz-transition: all 0.25s ease-in-out;
                        }
                    }
                    &white {
                        background-color: var(--color-white);
                        .btn-secondary {
                            background: none !important;
                            border: 1px solid var(--color-grey-20);
                            color: var(--color-grey-19);
                        }
                        .dropdown-toggle {
                            color: var(--color-grey-19) !important;
                            border: 1px solid var(--color-grey-20) !important;
                            border-radius: 7px;

                            @include respond-below(sm) {
                                font-size: var(--text-sm);
                            }
                        }
                        &.show > .dropdown-toggle:after {
                            transform: rotate(180deg);
                            -webkit-transform: rotate(180deg);
                            -moz-transform: rotate(180deg);
                            transition: all 0.25s ease-in-out;
                            -webkit-transition: all 0.25s ease-in-out;
                            -moz-transition: all 0.25s ease-in-out;
                        }
                    }
                    &date {
                        border: none !important;

                        &- {
                            &menu {
                                &.show {
                                    border: none;
                                    padding: 0;
                                }
                            }
                            &selected {
                                padding: var(--size-2) 0;
                                margin-bottom: var(--size-2);
                                border-bottom: 1px solid var(--color-grey-30);
                            }
                            &title {
                                color: var(--color-grey-15);
                            }
                            &input {
                                position: absolute;
                                top: -44px;
                                left: 5px;
                                z-index: var(--z-20);
                                background-color: transparent;
                                color: var(--color-grey-20);
                                font-weight: var(--font-normal);
                                opacity: 0;
                                border: none;
                            }
                            &picker {
                                border: 1px solid var(--color-grey-21);
                                padding: var(--size-2) var(--size-2);
                            }
                        }
                    }
                    &reset {
                        color: var(--color-red);
                        font-weight: var(--font-normal);
                        font-size: var(--text-xs);
                        cursor: pointer;

                        &- {
                            &menu {
                                &.show {
                                    width: 345px;
                                }
                            }
                        }
                    }
                    &All {
                        font-size: var(--text-sm);
                        font-weight: var(--font-normal);
                        color: var(--color-black-6);
                    }
                    &Allwhite {
                        color: var(--color-white);
                    }
                    &bid {
                        position: relative;
                        .btn-secondary {
                            background: var(--color-secondary) !important;
                            color: var(--color-white);
                            border: none !important;
                            font-size: var(--text-md);
                            font-weight: var(--font-normal);
                        }
                        :after {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: 15px;
                            margin-right: var(--size-4);
                        }
                        .dropdown-toggle {
                            background: var(--color-secondary) !important;
                            color: var(--color-white) !important;
                        }
                    }
                    &bidwrap {
                        position: absolute;
                        left: 0;
                        top: -25px;
                    }
                    &ignore {
                        .btn-secondary {
                            background: none !important;
                            color: var(--color-black) !important;
                            border: 1px solid var(--color-grey-18) !important;
                            color: var(--color-grey-29) !important;
                            font-size: var(--text-md);
                            font-weight: var(--font-normal);
                        }
                        :after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                    }
                    &ask {
                        .btn-secondary {
                            background: none !important;
                            color: var(--color-grey-29) !important;
                            border: 1px solid var(--color-primary) !important;
                            font-size: var(--text-md) !important;
                            font-weight: var(--font-normal) !important;
                        }
                    }
                }
            }

            &measuring {
                position: absolute;
                top: 0 !important;
                bottom: 0;
                margin: auto;
                font-weight: bold;
                width: 30px;
                height: 30px;
                right: 6px;
                z-index: 11;
                background: #3a4ee8;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                font-size: 30px;
                flex-shrink: 0;

                &::before,
                &::after {
                    position: absolute;
                    content: "";
                    width: 0.4em;
                    margin: auto;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 2px;
                    background-color: #fff;
                }
                &::after {
                    transform: rotate(90deg);
                }
            }

            &pro {
                font-size: 14px;
                padding: 5px 10px;
                color: #ffffff;
                background: transparent linear-gradient(107deg, #f2c25b 0%, #ff8c00 100%) 0% 0% no-repeat padding-box;
                border-radius: 5px;
            }
        }

        &-- {
            &facebook {
            }
            &google {
            }
        }
    }
}
