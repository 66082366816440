#{$ap}- {
    &page {
        &__ {
            &error {
                height: 100vh;
                background-color: #F5F9FA;

                &- {
                    &wrap {
                        /* background: red; */
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        position: relative;

                        &::before {
                            content: "";
                            width: 300px;
                            height: 300px;
                            margin: 0 auto;
                            background: transparent linear-gradient(
                            180deg, #ECF7FF 0%, #F5F9FA 100%) 0% 0% no-repeat padding-box;
                            border-radius: 100%;
                            position: absolute;
                            z-index: 0;
                        }

                        @media (min-width: 768px) {
                            &::before {
                                content: "";
                                width: 460px;
                                height: 460px;
                            }
                        }
                    }
                    &title {
                        font-size: var(--text-2xl);
                        line-height: calc(var(--text-2xl) + 10px);
                        font-weight: var(--font-medium);
                        margin-bottom: var(--size-6);

                        @media (min-width: 768px) {
                            font-size: var(--text-4xl);
                            line-height: calc(var(--text-4xl) + 10px);
                        }
                    }
                    &project {
                        // padding-top: 50px;

                        @media (min-width: 768px) {
                            padding-top: 100px;
                        }
                    }
                }
            }
        }
    }
}