#{$ap}-{
    &myproject{
        &__{
            &customer{
                &-{
                    &call{
                        font-size: var(--text-sm);
                        font-weight: var(--font-normal);
                        color: var(--color-black-5);
                        
                    }
                }
            }
            &completed{
                &-{
                    &amount{
                        font-size: var(--text-md);
                        font-weight: var(--font-normal);
                        color: var(--color-black-5);
                    }
                    &cost{
                        font-size: var(--text-md);
                        font-weight: var(--font-medium);
                        color: var(--color-secondary);
                    }
                    &hr{
                        font-size: var(--text-md);
                        font-weight: var(--font-normal);
                        color: var(--color-grey-24);
                    }
                    &text{
                        font-size: var(--text-sm);
                        // font-weight: var(--font-light);
                        color: var(--color-grey-25);
                    }
                    &hours{
                        font-size: var(--text-sm);
                        // font-weight: var(--font-light);
                        color: var(--color-black);
                    }
                    &time{
                        font-size: var(--text-sm);
                        font-weight: var(--font-bold);
                        color: var(--color-grey-26);
                    }
                    &initial{
                        font-size: var(--text-sm);
                        // font-weight: var(--font-light);
                        color: var(--color-grey-27);
                        border-bottom: 1px dotted var(--color-grey-5);
                    }
                    &total{
                        font-size: var(--text-sm);
                        // font-weight: var(--font-light);
                        color: var(--color-secondary);
                        border-bottom: 1px dotted var(--color-grey-5);
                    }
                    &due{
                        font-size: var(--text-sm);
                        font-weight: var(--font-medium);
                        color: var(--color-grey-28);
                    }
                    &complete{
                        background-color: var(--color-secondary);
                        border-radius: var(--rounded);
                        cursor: pointer;
                        //box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.08) !important;
                        box-shadow: 0px 3px 6px #00000029 !important;
                        &--{
                            &text{
                                font-size: var(--text-md);
                                font-weight: var(--font-normal);
                                color:var(--color-white);
                            }
                            &correct{
                                color: var(--color-white) !important;
                            }
                        }
                    }
                    &cancel{
                        border: 1px solid var(--color-grey-30);
                        border-radius: var(--rounded);
                        cursor: pointer;
                        &--{
                            &text{
                                font-size: var(--text-md);
                                font-weight: var(--font-normal);
                                color: var(--color-grey-29);
                            }
                            &icon{
                                color: var(--color-grey-30);
                            }
                        }
                    }
                }
            }
        }
    }
}