#{$ap}-{
    &projects{
        &__{
            &navtab{
                background-color: #bbd3e8;
                color: var(--color-white);
                border:none;
                margin-top:var(--size-1);
            }
            // &navlink{
            //         :hover{
            //             border-top: 3px solid var(--color-primary) !important;
            //         }
            // }
            &gray{
                background-color: var(--color-white) !important;
                color: var(--color-black);
                :hover{
                    color: var(--color-black) !important;
                }
            }
            &white{
                background-color: var(--color-white) !important;
                border-radius: var(--rounded);
                border: 1px solid var(--color-grey-20);
                color: var(--color-black);
            }
            &all{
                font-size: var(--text-lg);
                font-weight: var(--font-normal);
                color: var(--color-black-6);
                &-text{
                    font-size: var(--text-lg);
                    font-weight: var(--font-normal);
                    color: var(--color-grey-19);
                }
            }

            &btn {
                &-- {
                    &leadprojects {
                        @media (min-width: 768px) {
                            width: 50%;
                        }
                        @media (min-width: 769px) and (max-width: 1025px) {
                            margin-bottom: var(--size-2) !important;
                            padding: 0 !important;
                            width: 100%;

                            &:last-child {
                                margin-bottom: 0 !important;
                            }
                        }

                        & .ebw-button {
                            padding: var(--size-2) var(--size-1);
                        }
                    }
                }
            }
        }
    }
}