#{$ap}- {
    &my {
        &__ {
            &work {
                &- {
                    &album {
                        background-color: var(--color-blue-2);
                        color: var(--color-white);
                        padding: var(--size-2) var(--size-4);
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 2px;
                        transition: all 0.25s ease-in-out;
                        -webkit-transition: all 0.25s ease-in-out;
                        -moz-transition: all 0.25s ease-in-out;

                        &:hover {
                            background-color: var(--color-secondary);
                            color: var(--color-white);
                            transition: all 0.25s ease-in-out;
                            -webkit-transition: all 0.25s ease-in-out;
                            -moz-transition: all 0.25s ease-in-out;
                        }
                    }
                }
            }
            &album {
                box-shadow: 0px 3px 6px #72727229;
                border: 1px solid var(--color-grey-14);
                border-radius: 8px;
                padding: var(--size-2);
                margin-bottom: var(--size-6);

                @include respond-below(lg) {
                    margin-bottom: var(--size-4);
                }

                &s {
                }

                &- {
                    &folder {
                        padding: 0 var(--size-4);
                        position: relative;
                        z-index: var(--z-2);
                    }
                }
            }

            //- MY WORK UPLOADS PAGE: START
            &uploads {
                &- {
                    &file {
                        margin: var(--size-12) var(--size-12);
                        background-color: rgba(#3497fd, 0.08);
                        border: 2px dashed rgba(#1875fb, 0.25);

                        @include respond-above(lg) {
                            margin: var(--size-12) calc(var(--size-12) * 3);
                        }
                        @include respond-below(sm) {
                            margin: 0;
                        }

                        &s {
                            @include respond-above(md) {
                                padding: 0 20%;
                            }
                        }
                    }
                    &option {
                        background: #ffffffbf;
                        width: 35px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        right: 0;
                        margin-top: 10px;
                        margin-right: 10px;
                        z-index: 1;
                    }

                    &inwrap {
                        padding: calc(var(--size-8) * 2) 0;
                    }
                    &imgwrap {
                        & > div {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                        }
                    }
                    &text {
                        color: var(--color-grey-8);
                        letter-spacing: 0.26px;
                        font-weight: var(--font-medium);
                    }

                    &empty {
                        &-- {
                            &title {
                                font-size: var(--text-5xl);
                                color: var(--color-grey-7);
                                letter-spacing: 0.48px;
                            }
                            &text {
                                font-size: var(--text-lg);
                                font-weight: var(--font-medium);
                                letter-spacing: 0.32px;
                                color: var(--color-grey-8);
                            }
                        }
                    }
                    &more {
                        position: absolute;
                        top: 0;
                        right: 0;
                        background-color: var(--color-white);
                        opacity: 63%;
                    }
                    &img {
                        width: 100px;
                        height: 100px;
                        margin: 0 auto;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-color: #f3f3f3;
                    }

                    &video {
                        position: absolute;
                        top: calc(50% - 25px);
                        left: calc(50% - 14px);
                        display: block;
                        content: "";
                        box-sizing: border-box;
                        border-color: transparent transparent transparent #fff;
                        border-style: solid;
                        border-width: 26px 0 26px 40px;
                    }

                    &item {
                        display: inline-block;
                        position: relative;
                        margin-right: 25px;
                        margin-bottom: 20px;
                    }
                    &close {
                        position: absolute;
                        width: 20px !important;
                        height: 20px !important;
                        background: #e0e0e0;
                        border-radius: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        right: -10px;
                        top: -10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &uploads {
        &__ {
            &option {
                background: #ffffffbf;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                right: 0;
                margin-top: 10px;
                margin-right: 10px;
                z-index: 1;
            }
            &folder {
                width: 100%;
                // height: 130px;
                background-color: #ebebeb;
                border-radius: 5px;
                overflow: hidden;

                &- {
                    &wrap {
                        // width: 160px;
                        border-radius: 5px;
                        overflow: hidden;
                    }
                    &img {
                        width: 100%;
                        height: 100%;
                        border-radius: 5px;
                        overflow: hidden;

                        & img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }
                    }
                    &name {
                        color: #2a2a2a;
                        text-align: center;
                        font-size: var(--text-sm);

                        & > input {
                            text-align: center;
                            background: none !important;
                            padding-bottom: 10px;
                        }
                    }
                }
            }

            &slider {
                width: 100%;
                height: 200px;
                border-radius: 5px;
                overflow: hidden;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;

                &- {
                    &wrap {
                        width: 200px;
                        border-radius: 5px;
                        // background-color: #EBEBEB;
                        overflow: hidden;
                    }
                    &img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                    &name {
                        color: #2a2a2a;
                        font-size: var(--text-sm);
                    }
                }
            }
        }
    }
}
