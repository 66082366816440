#{$ap}- {
    &footer {
        width:100%;
        height:auto;

        @include respond-below(md){
            margin-bottom:calc(var(--size-8) + var(--size-9));
        }
        @include respond-below(sm){
            margin-bottom:calc(var(--size-7) * 2);
        }
        
        &__ {
            &inner{
                background-color: var(--color-footer);
                color:var(--color-white);
            }
            &wrap{
                @include respond-below(md){
                    margin-bottom:var(--size-8);
                }
                
                &-{
                    &category{
                        font-size:var(--text-lg);
                        color:var(--color-section-bg);
                        letter-spacing: 0.26px;
                        margin-bottom:var(--size-4);

                        &--{
                            &available{
                                font-size: var(--text-xl);                                
                            }
                        }
                    }
                }
            }

            &brand {
                width: 70px;
            }
            &link{
                font-size: var(--text-md);
                color:var(--color-section-bg);
                opacity: 0.47;
                letter-spacing: 0.19px;
                font-weight: var(--font-light);
                padding-bottom:var(--size-1);

                &:hover{
                    color:var(--color-secondary);
                    opacity: 1;
                }
            }
            &address{
               margin-top:var(--size-8);

               @include respond-above(md){
                    margin-top:var(--size-14);
               }
               &-{
                    &info{
                            border: 1px solid rgba(#9C9C9C, 0.45);
                            border-radius: 5px;
                            padding:var(--size-3);
                    }
                    &desc{
                        color:var(--color-section-bg);
                        font-size: var(--text-sm);
                        font-weight: var(--font-light);
                        opacity: 0.75;

                        @include respond-below(sm){
                            margin-bottom:var(--size-2);
                        }
                    }
                    &link{
                            color:var(--color-section-bg);
                            font-size: var(--text-sm);
                            font-weight: var(--font-light);
                            opacity: 0.75;

                            &:hover{
                                color:var(--color-secondary);
                                opacity: 1;
                            }
                    }
                }
            }
            &btm{
                background-color: #F5F9FA;
                color:var(--color-footer);
                padding:var(--size-3) 0;
            }
            &copyright{
                &-{
                    &desc{
                        color:var(--color-footer);
                        font-weight: var(--font-light);

                        @include respond-below(sm){
                            margin:calc(var(--size-1) - 3px);
                        }
                    }
                    &link{
                        color:var(--color-footer);
                        font-weight: var(--font-light);

                        &:first-child{
                            padding-right:var(--size-3);
                            border-right:1px solid var(--color-footer);
                        }

                        &:hover{
                            color:var(--color-secondary);
                        }
                    }
                }
            }
        }
    }
}
