@font-face {
  font-family: 'icomoon';
  src:  url('../../fonts/icomoon.eot?lui5be');
  src:  url('../../fonts/icomoon.eot?lui5be#iefix') format('embedded-opentype'),
    url('../../fonts/icomoon.ttf?lui5be') format('truetype'),
    url('../../fonts/icomoon.woff?lui5be') format('woff'),
    url('../../fonts/icomoon.svg?lui5be#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chat:before {
  content: "\e921";
}
.icon-notification:before {
  content: "\e915";
}
.icon-support-headphone:before {
  content: "\e91f";
}
.icon-mail:before {
  content: "\e920";
}
.icon-projects:before {
  content: "\e91c";
}
.icon-home:before {
  content: "\e91d";
}
.icon-my-projects:before {
  content: "\e91e";
}
.icon-more-horizontal:before {
  content: "\e913";
}
.icon-add:before {
  content: "\e91b";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-left:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-arrow-straight:before {
  content: "\e903";
}
.icon-arrow-top:before {
  content: "\e904";
}
.icon-bidding:before {
  content: "\e905";
}
.icon-buy:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e907";
}
.icon-comments:before {
  content: "\e908";
}
.icon-conditions:before {
  content: "\e909";
}
.icon-date:before {
  content: "\e90a";
}
.icon-document:before {
  content: "\e90b";
}
.icon-edit:before {
  content: "\e90c";
}
.icon-faqs:before {
  content: "\e90d";
}
.icon-filter:before {
  content: "\e90e";
}
.icon-hamburger:before {
  content: "\e90f";
}
.icon-location:before {
  content: "\e910";
}
.icon-login:before {
  content: "\e911";
}
.icon-logout:before {
  content: "\e912";
}
.icon-more-vertical:before {
  content: "\e914";
}
.icon-phone:before {
  content: "\e916";
}
.icon-privacy:before {
  content: "\e917";
}
.icon-review:before {
  content: "\e918";
}
.icon-support:before {
  content: "\e919";
}
.icon-works:before {
  content: "\e91a";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-delete:before {
  content: "\e9ac";
}
.icon-attachment:before {
  content: "\e9cd";
}
