#{$ap}- {
    &title {
       &__ {
           &entry {
            font-size: var(--text-xl);
            line-height: calc(var(--text-xl) + 8px);
            font-weight: var(--font-bold);
            color: var(--color-black);
            position: relative;
            padding-bottom: var(--size-2);

                &:before {
                  content: "";
                  width: 68px;
                  height: 2px;
                  position: absolute; 
                  left: 0;
                  bottom: 0;
                   background-color: var(--color-primary);
                }
           }

           &primary {
               font-size: var(--text-2xl);
               line-height: calc(var(--text-4xl) + 8px);
               font-weight: var(--font-bold);
               color: var(--color-black);

               @include respond-above(md) {
                font-size: var(--text-6xl);
                line-height: calc(var(--text-6xl) + 8px);
               }

               &-- {
                   &white {
                       color: var(--color-white);
                   }
               }
           }

           &secondary {
                font-size: var(--text-xl);
                line-height: calc(var(--text-xl) + 8px);
                font-weight: var(--font-bold);

                @include respond-above(md) {
                    font-size: var(--text-3xl);
                    line-height: calc(var(--text-3xl) + 8px);
                }

                &-- {
                    &gray {
                        color: var(--color-grey-2);
                    }
                }
           }
       }
    }

    &text {
        &-- {
            &primary {
                color: var(--color-primary);
            }
            &S {
                font-size: var(--text-sm);
            }
            &M {
                font-size: var(--text-md);
            }
            &L {
                font-size: var(--text-lg);
            }
            &xl {
                font-size: var(--text-xl);
            }
        }
    }

    &para {
        &-- {
            &primary {
                color: var(--color-primary);
            }
            &grey {
                color: #585858;
            }
            &sm {
                font-size: var(--text-sm);
            }
        }
    }

    &info {
        font-size: var(--text-xs);
    }

    &error {
        color: red;
        padding: 10px;
        font-size: var(--text-xs);
        border: 1px solid red;
        background: #ff000026;
        border-radius: 5px;
    }
}
