.ReactModal__Overlay {
    position: absolute !important;
    z-index: 99999;
  }

  .ReactModal__Body--open #root {
    overflow: hidden;
    height: 100vh;
  }
  
  /* If using Option 1, also add: */
  .custom-overlay {
    position: absolute !important;
  }
  
  .custom-modal {
    /* Any additional modal styles */
  }