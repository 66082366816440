[class^="icon-"], [class*=" icon-"] {
    font-size: var(--text-md);
}

#{$ap}- {
    &font {
        &__ {
            &size {
                &-xxs {
                    font-size: var(--text-xxs); // 10px
                }
                &-xs {
                    font-size: var(--text-xs); // 12px
                }
                &-sm {
                    font-size: var(--text-sm); // 14px
                }
                &-md {
                    font-size: var(--text-md); // 16px
                }
                &-lg {
                    font-size: var(--text-lg); // 18px
                }
                &-xl {
                    font-size: var(--text-xl); // 20px
                }
                &-2xl {
                    font-size: var(--text-2xl); // 22px
                }
                &-3xl {
                    font-size: var(--text-3xl); // 24px
                }
            }
            &color {
                &- {
                    &primary {
                        color: var(--color-primary);
                    }
                    &secondary {
                        color: var(--color-secondary);
                    }
                    &gray {
                        color: dimgrey;
                    }
                    &black {
                        color: var(--color-black);
                    }
                }
            }
        }
    }
}