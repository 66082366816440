#{$ap}- {
    &project{
        &__{
            &chat{
                // width:550px;
                // min-height:100vh;
                // top:0;
                // right:0;
                // z-index: 1000;
                // position:fixed;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0 6px 6px 3px #a1a1a121;
                border-top:6px solid var(--color-blue-2);
                overflow:hidden;  

                @include respond-below(sm){
                    width:100%;
                }
                
                &-{
                    &formgroup{
                        position:fixed;
                        bottom:10px;
                        background-color: var(--color-white);
                        width: 480px;

                        @include respond-above(sm){
                            padding-top:var(--size-4);
                        }

                        @include respond-below(sm){
                            width:100%;
                            bottom:0;
                            left:0;
                            padding:var(--size-2) var(--size-2);
                        }
                    }
                    &col{
                        min-height:100vh;
                        width:550px;
                        top:0;
                        right:0;
                        z-index: 1050;

                        @include respond-below(sm){
                            width:100%;
                        }
                    }
                    &inner{
                        padding:var(--size-4) var(--size-6) var(--size-4) var(--size-10);
                        min-height:100vh;

                        @include respond-below(sm){
                            width:100%;
                            padding:var(--size-6) var(--size-3);
                        }
                    }
                    &project{
                        font-size: var(--text-lg);
                        color:var(--color-black-2);
                    }
                    &hide{
                        font-size: var(--text-sm);
                        cursor: pointer;

                        &:hover{
                            &> .ebw-project__chat-hide--arrow{
                                transform: translateX(5px);-webkit-transform: translateX(5px);-moz-transform: translateX(5px);
                                transition: all 0.2s linear;-webkit-transition: all 0.2s linear;-moz-transition: all 0.2s linear;
                            }
                        }
                        &--{
                            &arrow{
                                color:var(--color-blue-2);
                                font-weight: var(--font-bold) !important;
                                transition: all 0.2s linear;-webkit-transition: all 0.2s linear;-moz-transition: all 0.2s linear;
                            }
                        }
                    }
                    &img{
                        width:var(--size-9);
                        height:var(--size-9);
                        border-radius: var(--rounded-full);
                        background: var(--color-grey-25);
                        overflow-y: hidden;

                        img{
                            width:var(--size-9);
                            height:var(--size-9);
                            border-radius: var(--rounded-full);
                            background: var(--color-grey-25);
                            object-fit: cover;
                            object-position: top;
                        }
                    }
                    &profile{
                        &--{
                            &name{
                                font-size: var(--text-md);
                                color:var(--color-black-2);
                            }
                            &location{
                                font-size: var(--text-xs);
                                color:var(--color-black-2);
                            }
                            &desc{
                                font-size: var(--text-sm);
                                color:var(--color-grey-22);
                                margin-top:var(--size-3);
                            }
                        }
                    }

                    &conv{
                        margin:var(--size-2) 0;

                        &s{
                            margin:var(--size-5) 0;
                            padding-bottom:var(--size-12);
                            width:100%;
                            height:60vh;
                            overflow-y: scroll;

                            &::-webkit-scrollbar{
                                display: none;
                            }
                            
                            &--{
                                &max-height{
                                    height:60vh;
                                }
                                &min-height{
                                    height:50vh;
                                }
                            }
                        }
                        &--{
                            &img{
                                min-width:var(--size-6);
                                width:var(--size-6);
                                height: var(--size-6);
                                border-radius: var(--rounded-full);
                                background-color: var(--color-grey-2);
                                overflow:hidden;
        
                                img{
                                    min-width:var(--size-6);
                                    width:var(--size-6);
                                    height: var(--size-6);
                                    border-radius: var(--rounded-full);
                                    background-color: var(--color-grey-2);
                                    object-fit: cover;
                                    object-position: top;
                                }
                            }

                            &text{

                            }
                            &msg{
                                background: #F6F6F6 0% 0% no-repeat padding-box;
                                padding:var(--size-2);
                                border-radius: 6px;
                                font-size: var(--text-sm);

                                &:before{
                                    font-family: "icomoon";
                                    content: '\e921';
                                    display: block;
                                    position: absolute;      
                                    left: -16px;
                                    top:5px;
                                    z-index: -2;
                                    font-size: var(--text-2xl);
                                    color:#f6f6f6;
                                    transform: rotate(-90deg);
                                }
                            }
                            &usermsg{
                                background: #D5E6FF 0% 0% no-repeat padding-box;
                                padding:var(--size-2);
                                border-radius: 6px;
                                font-size: var(--text-sm);

                                &:after{
                                    font-family: "icomoon";
                                    content: '\e921';
                                    display: block;
                                    position: absolute;      
                                    right: -15px;
                                    top:5px;
                                    z-index: -2;
                                    font-size: var(--text-2xl);
                                    color:#D5E6FF;
                                    transform: rotate(90deg);
                                }
                            }
                            &delete{
                                color:var(--color-red);
                                font-weight: var(--font-medium);
                                cursor: pointer;
                            }
                            &fileimgvid{
                                // width:calc(var(--size-10) + var(--size-5));
                                // height:calc(var(--size-10) + var(--size-5));
                                background-color: #D5E6FF;
                                overflow:hidden;
                                border-radius: 5px;
                                padding: 10px;

                                img{
                                    max-width: 160px;
                                    // width:calc(var(--size-10) + var(--size-5));
                                    // height:calc(var(--size-10) + var(--size-5));
                                    // background-color: var(--color-grey-21);
                                    // object-fit: cover;
                                    // object-position: top;
                                }
                            }
                        }
                    } 
                }
            }

            &documents{
                padding:var(--size-2) 0;
                width:500px;
                overflow-x: auto;
                margin:0 auto;
                background-color: var(--color-white);
                position:absolute;
                bottom:60px;
                z-index: var(--z-10);

                @include respond-below(sm){
                    width:100%;
                    bottom:40px;

                    &::-webkit-scrollbar{
                        display: none;
                    }
                }

                &::-webkit-scrollbar{
                    height:5px;
                    visibility: hidden;
                }
               
                &:hover{
                    overflow-x: scroll;

                    &::-webkit-scrollbar{
                        height:5px;
                        visibility: visible;
                    }
                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                        height:5px;
                        border-radius: 50px;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 50px;
                        background-color: var(--color-grey-18);
                        outline: 1px solid var(--color-grey-21);
                    }
                }

                &-{
                    &img{
                        position:relative;
                        min-width:80px;
                        min-height:80px;
                        background-color: var(--color-grey-2);
                        //overflow:hidden;
                        margin:0 var(--size-1);
                        z-index:1;
                        transition:all 0.2s linear;-webkit-transition:all 0.2s linear;-moz-transition:all 0.2s linear;

                        @include respond-below(sm){
                            min-width:60px;
                            min-height:60px;
                        }

                        img{
                            position:relative;
                            width:80px;
                            height:80px;
                            background-color: var(--color-grey-2);
                            object-fit: cover;
                            object-position: top;  
                            z-index:1;    
                            transition:all 0.2s linear;-webkit-transition:all 0.2s linear;-moz-transition:all 0.2s linear;
                            
                            @include respond-below(sm){
                                width:60px;
                                height:60px;
                            }

                            &:hover{
                                border:2px solid var(--color-red);
                                transition:all 0.2s linear;-webkit-transition:all 0.2s linear;-moz-transition:all 0.2s linear;
                            }                     
                        }
                        &:hover{
                            &> .ebw-project__documents-delete{
                                opacity:1;
                                transition:all 0.1s linear;-webkit-transition:all 0.1s linear;-moz-transition:all 0.1s linear;
                            }
                        }
                    }
                    &delete{
                        position:absolute;
                        top:-8px;
                        right:-6px;
                        width:var(--size-4);
                        height:var(--size-4);
                        border-radius: var(--rounded-full);
                        background-color: var(--color-red);
                        color:var(--color-white);
                        font-weight: var(--font-bold);
                        z-index:2;
                        margin:0 auto;
                        cursor: pointer;
                        opacity:0;
                        transition:all 0.1s linear;-webkit-transition:all 0.1s linear;-moz-transition:all 0.1s linear;

                        &--{
                            &icon{
                                position:absolute;
                                top:4px;
                                right:4px;
                                font-size: var(--text-xs);
                                font-weight: var(--font-bold);
                            }
                        }
                    }
                }
            }
        }
    }
}