#{$ap}- {
    &achievements {
        &__ {
            &item {
                width: 33%;
                padding: 0 10px;
                position: relative;
                @include respond-above(md) {
                    padding: 0 25px;

                    &:after {
                        content: "";
                        width: 1px;
                        height: 160px;
                        background-color: #E4F3FC;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto 0;
                        z-index: 2;
                    }
                }
                &:last-child {
                    &:after {
                        display: none;
                    }
                }

                &s {
                    width: 100%;

                    &- {
                        &withMsg {
                            @include respond-above(lg) {
                                width: calc(100% - 500px);
                            }
                        }
                    }
                }
                &- {
                    &icon {
                        width: calc(var(--size-6));
                        height: calc(var(--size-6));
                        border-radius: 100%;
                        background-color: rgba(206, 233, 249, 0.54);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto;

                        @include respond-above(md) {
                            width: calc(var(--size-9) * 2);
                            height: calc(var(--size-9) * 2);
                            margin: 0;
                        }
                    }
                    &count {
                        font-size: var(--text-lg);
                        line-height: calc(var(--text-xl) + 8px);
                        font-weight: var(--font-semibold);
                        color: var(--color-grey-2);

                        @include respond-above(md) {
                            font-size: var(--text-6xl);
                            line-height: calc(var(--text-6xl) + 8px);
                        }
                    }
                    &title {
                        font-size: var(--text-sm);
                        line-height: calc(var(--text-sm) + 8px);
                        font-weight: var(--font-normal);
                        color: var(--color-grey-2);

                        @include respond-above(md) {
                            font-size: var(--text-md);
                            font-weight: var(--font-medium);
                            line-height: calc(var(--text-md) + 8px);
                        }
                    }
                    &para {
                        color: var(--color-grey-10);
                    }
                }
            }

            &msg {
                &- {
                    &box {
                        margin-top: 15px;

                        @include respond-above(lg) {
                            margin-top: 0px;
                        }

                        @include respond-above(lg) {
                            width: 500px;
                        }
                    }
                }
            }
        }
    }
    &slider {
        &__ {
            &navigation{
                display: flex;
                justify-content: flex-end;
                position: relative;
                margin-top: var(--size-3);
                
                &- {
                    &arrow {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        border: 1px solid var(--color-grey-5);
                        width: 33px;
                        height: 33px;
                        margin: var(--size-1);
                        color: var(--color-grey-5);

                        &:hover, &--active{
                            border: 1px solid var(--color-secondary) !important;
                            & i {
                                color: var(--color-secondary) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}